/*================ Font Variables ================*/

// Font Family
$primary-font: 'Poppins', sans-serif;

// Colors
$dark: #151515;
$dark-blue: #0C174F;
$blue: #13216A;
$sisal : #C4BB9E;

// Device Width
$breakpoints: (
    'desktop':          1800px,
    'medium-desktop':   1550px,
    'mini-desktop':     1440px,
    'laptop':           1199px,
    'ipad-landscape':   1024px,
    'ipad-portrait':    991px,
    'mobile-big':       767px,
    'mobile-small':     575px
);