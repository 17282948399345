// Crumbs
#crumbs {
    margin-bottom: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    a {
        color: white;
        margin-bottom: 20px;

        &:hover {
            + .arrow {
                &:after {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        &:last-of-type {
            + .arrow {
                background-image: url('../../../inc/assets/long-arrow-right-creame.svg');

                &:after {
                    background-color: white;
                }
            }
        }
    }

    span {
        display: inline-block;
        margin-bottom: 20px;
    }

    .arrow {
        background-image: url('../../../inc/assets/long-arrow-right-light.svg');
        width: 18px;
        height: 18px;
        background-size: 100%;
        display: inline-block;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 25px 20px 20px;
        position: relative;

        &:after {
            content: '';
            width: 14px;
            height: 14px;
            @include borderRadius(50%);
            background-color: $sisal;
            position: absolute;
            top: 50%;
            right: -5px;
            z-index: -1;
            @include translateY(-50%);
            @include transitionAll(0.3s ease-in-out);
        }
    }
}

// 1. Hero Banner Block Homepage
.hero-banner-block-homepage {
    position: relative;
    background-size: cover !important;
    background-position: center center !important;
    overflow: hidden;
    background-blend-mode: darken;
    transition: 3s;

    .carousel {
        position: absolute;
        width: 100%;

        .carousel-inner {
            height: 100%;
            
            .carousel-item {
                height: 100%;
                

                img {
                    height: 100%;
                    min-width: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
    }

    .overlay {
        &:not(.image-overlay) {
            background: rgba(0,0,0,0.75);
            background: linear-gradient(90deg, rgba(0,0,0,0.50) 0%, rgba(0,0,0,0.50) 50%, rgba(255,255,255,0) 100%);
        }
    }

    .row {
        // height: 900px;
        min-height: 75vh;
        color: white;

        &.flex-row-reverse {
            position: relative;
            .title-col {
                margin-top: 0;
            }

            &:after {
                position: absolute;
                bottom: -50px;
                left: 180px;
                content: '';
                width: 2px;
                height: 300px;
                background: white;
                @include rotate(40deg);
            }
        }
    }

    .title-col {
        margin-top: 150px;
        margin-bottom: 80px;

        .title-main {
            margin-bottom: 15px;
        }
    }

    .container {
        position: relative;
        z-index: 1;
    }
}

.overlay {
    &:not(.image-overlay) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.3);
        // background: rgba(0,0,0,0.75);
        // background: linear-gradient(90deg, rgba(0,0,0,0.50) 0%, rgba(0,0,0,0.50) 50%, rgba(255,255,255,0) 100%);
        z-index: 1;
    }
}

.explore-btn {
    position: absolute;
    bottom: 60px;
    left: 50%;
    font-size: 16px;
    line-height: 20px;
    color: white;
    font-weight: 400;
    text-align: center;
    z-index: 2;
    @include translateX(-50%);

    &:hover {
        color: #fff;

        .arrow {
            &:after {
                width: 45px;
                height: 45px;
            }
        }
    }

    .arrow {
        position: relative;
        display: inline-block;
        margin-top: 25px;
        @include rotate(90deg);

        img {
            width: 25px;
            display: block;
            position: relative;
            z-index: 1;
        }

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: -10px;
            width: 27px;
            height: 27px;
            background-color: $sisal;
            @include borderRadius(27px);
            z-index: 0;
            @include translateY(-50%);
            @include transitionAll(0.3s ease-in-out);
        }
    }
}



// 2. Hero Banner Block Subpage
.hero-banner-block-subpage {
    position: relative;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    color: white;

   .overlay {
       &:not(.image-overlay) {
           background: rgba(0,0,0,0.75);
           background: linear-gradient(90deg, rgba(0,0,0,0.50) 0%, rgba(0,0,0,0.50) 50%, rgba(255,255,255,0) 100%);
       }
   }

    .container {
        position: relative;
        z-index: 1;

        .row {
            min-height: 75vh;
            padding-top: 200px;
            padding-bottom: 150px;
        }
    }

    .title {
        margin-bottom: 15px;
    }

    .brand-logos {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        margin-left: -1rem;
        margin-right: -1rem;

        .brand-logo {
            padding-left: 1rem;
            padding-right: 1rem;
            
            img {
                max-width: 120px;
            }
        }
    }
}


// 3. Content With Image Block
.content-with-image-block {
    padding: 0;

    .image-block {
        position: relative;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        // @include borderRadius(0 10px 10px 0);

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &:not(.image-overlay) {
                background-color: rgba(0,0,0,0.6);
            }

            &.image-overlay {
                opacity: 0.25;
            }

            &.bottom-right-corner {
                background-size: 200px;
                background-position: bottom 40px right 40px;
                background-repeat: no-repeat;
            }

            &.cover-image {
                background-size: 70%;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }

        .caption {
            background-color: rgba(0,0,0,0.6);
            color: white;
            padding: 1rem 1.5rem;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 10;
        }
    }

    .title {
        margin-bottom: 55px;
    }

    .content-block {
        padding: 100px 6rem;

        .title {
            color: $dark;
        }

        h6 {
            width: 90%;
            margin-bottom: 20px;
        }

        p {
            &:last-of-type {
                margin-bottom: 40px;
            }
        }
    }

    // .row {
    //     &.flex-sm-row-reverse {
    //         .content-block {
    //             padding-right: 6rem !important;
    //         }
    //     }

    //     &:not(.flex-sm-row-reverse) {
    //         .content-block {
    //             padding-left: 6rem !important;
    //         }
    //     }
    // }

    .btn {
        margin-left: -15px;
    }
}


// 4. Branded Image with Content
.branded-image-with-content {
    padding: 130px 0;
    color: white;

    .title {
        margin-bottom: 50px;
    }

    h6 {
        margin-bottom: 20px;
    }

    p {
        &:last-of-type {
            margin-bottom: 40px;
        }
    }

    .btn {
        margin-left: -15px;
    }
}

// 5. Our Brands Block
.our-brands-block {
    padding: 75px 0;

    .title {
        color: $blue;
        margin-bottom: 40px;
    }

    p {
        &:last-of-type {
            margin-bottom: 80px;
        }
    }

    .our-brands {
        .our-brand {
            margin-bottom: 20px;

            &:not(.has-background) {
                .our-brands-wrapper {
                    background-color: $blue;
                }
            }

            .our-brands-wrapper {
                box-shadow: 0 8px 24px rgba(0,0,0,0.15);
                @include borderRadius(10px);
                border-bottom: 10px solid $blue;
                @include transitionAll(0.3s ease-in-out);
                position: relative;
                width: 100%;
                border-radius: 10px;
                overflow: hidden;

                &:before {
                    content: "";
                    display: block;
                    width: 100%;
                }



                .image-wrapper {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%!important;
                    height: 100%!important;
                    
                    img {
                        -o-object-fit: cover;
                        object-fit: cover;
                        min-height: 100%;
                    }
                }

                .content-wrapper {
                    z-index: 10;
                    padding: 40px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    text-align: center;

                    .title {
                        color: white;
                        margin-bottom: 20px;
                    }

                    p {
                        color: white;
                        &:last-of-type {
                            margin-bottom: 20px;
                        }
                    }

                    .btn-expand {
                        .hover-arrow {
                            display: none;
                        }
                    }

                    .text-end {
                        bottom: 40px;
                        left: 40px;
                        right: 40px;
                    }

                    &:hover {
                        p, .text-end{
                            opacity: 1;
                        }

                        .btn {
                            &:after {
                                width: 100%;
                                height: 40px;
                            }
                        }

                        .btn-expand {

                            img {
                                display: none;
                            }

                            .hover-arrow {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }

    

    .two-columns {
        &.our-brands {
            .our-brand {
                .our-brands-wrapper {
                    &:before {
                        padding-top: 65.25%;
                    }
                }
            }
        }
    }

    .three-columns {
        &.our-brands {
            .our-brand {
                .our-brands-wrapper {
                    &:before {
                        padding-top: 125%;
                    }
                }
            }
        }
    }

    &.white-background {
        .our-brands {
            .our-brand {
                .our-brands-wrapper {
                    &:hover {
                        border-bottom-color: $sisal;

                        .title {
                            color: white;
                        }

                        .btn {
                            color: white;

                            &:after {
                                background-color: $sisal;
                            }
                        }
                    }
                }
            }
        }
    }

    &.blue-background {
        background-color: $blue;
        color: white;

        .title {
            color: white;
        }

        .our-brands-wrapper {
            background-color: white;
            border-bottom-color: $sisal;
            color: $dark;

            .title {
                color: $dark;
            }

            &:hover {
                color: $dark;
                background-color: $sisal;
                border-bottom-color: white;

                .title {
                    color: $dark;
                }

                .btn {
                    color: white;
                }
            }
        }
    }
}

.our-sites-block {
    margin-top: 80px;
    margin-bottom: 80px;

    .our-sites-content {
        padding-bottom: 40px;
    }

    .our-sites {
        .our-site {
            margin-bottom: 20px;

            .our-site-wrapper {
                box-shadow: 0 8px 24px rgba(0,0,0,0.15);
                @include borderRadius(10px);
                overflow: hidden;
                width: 100%;

                h5 {
                    min-height: 50px;
                    
                    a {
                        color: $dark;
                    }
                }

                .image-wrapper {
                    img {
                        height: 220px;
                        object-fit: cover;
                        object-position: center center;
                    }
                }

                .content-wrapper {
                    padding: 20px;

                    *:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}


// 6. Core Values Block
.core-values-block {
    padding: 75px 0 50px;

    .title {
        margin-bottom: 40px;
    }

    p {
        margin-bottom: 80px;
    }

    .core-values-wrapper {
        padding: 40px;
        margin-bottom: 20px;
        color: $dark;
        background-color: white;
        border-bottom: 10px solid $blue;
        @include borderRadius(10px);
        @include box-shadow(0 0 24px rgba(0,0,0,.15));

        .core-values-img {
            margin-bottom: 60px;

            img {
                height: 60px;
                width: auto;
            }
        }

        .core-values-content {

            .title {
                margin-bottom: 20px;
            }

            p {
                margin-bottom: 15px;
            }
        }
    }

    &.blue-background {
        background-color: $blue;
        color: white;

        .core-values-wrapper {
            border-color: $sisal;
        }
    }

    &.image-background {
        background-size: cover;
        background-position: top right;
        color: $sisal;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.7);
        }

        .container {
            position: relative;
            z-index: 1;
        }

        p {
            color: white;
        }

        .core-values-wrapper {
            border-color: $sisal;

            p {
                color: $dark;
            }
        }
    }
}

// 7. Content, Logo and Image Block
.content-logo-image-block {
    .content-block {
        .logo-icon {
            width: auto;
            max-width: 100%;
            margin-bottom: 30px;
        }

        .title {
            color: $blue;
            margin-bottom: 15px;
        }

        .social-links {
            display: flex;
            list-style: none;
            padding-left: 0;
            margin-bottom: 40px;

            li {
                &:not(:last-child) {
                    margin-right: 10px;
                }
                
                a {
                    width: 24px;
                    height: 24px;
                    @include flex;
                    @include justify-content(center);
                    @include align-items(center);
                    border-radius: 100%;
                    background-color: $dark-blue;
                    padding: 5px;

                    img {
                        width: 10px;
                        height: auto;
                    }
                }
            }
        }

        .full-title {
            background-color: $dark-blue;
            padding: 24px 20px;

            h6 {
                color: white;
            }

            .btn {
                color: white;
                &:after {
                    background-color: white;
                }

                &:hover {
                    color: $dark-blue;
                }
            }
        }
    }

    .image-block {

        // @include borderRadius(0 10px 10px 0);

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &:not(.image-overlay) {
                background-color: rgba(0,0,0,0.6);
            }

            &.image-overlay {
                opacity: 0.25;
            }

            &.bottom-right-corner {
                background-size: 200px;
                background-position: bottom 40px right 40px;
                background-repeat: no-repeat;
            }

            &.cover-image {
                background-size: 70%;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }

        &:before, &:after {
            display: none;
        }
    }

    .row {
        &.flex-sm-row-reverse {
            .content-block {
                padding-right: 6rem !important;
            }
        }

        &:not(.flex-sm-row-reverse) {
            .content-block {
                padding-left: 6rem !important;
            }
        }
    }
}


// 8. Content Block
.content-block {
    padding: 75px 0;

    .logo-icon {
        width: auto;
        margin-bottom: 50px;
    }

    .title {
        color: $blue;
        margin-bottom: 50px;
    }

    .btn {
        margin-left: -15px;
    }
}


// 9. Gallery Block
.gallery-block {
    padding: 75px 0;

    .title {
        color: $blue;
        margin-bottom: 40px;
    }

    .swiper-slide {
        img {
            @include borderRadius(10px);
        }

        [class^="col-"] {
            margin-bottom: 20px;
            overflow: hidden;
        }
    }

    .swiper-button-next, .swiper-button-prev {
        width: 40px;
        height: 40px;
        background-size: 100%;
        background-position: center;
        top: 50%;
        background-repeat: no-repeat;
        z-index: 1;
        @include translateY(-50%);

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: -20px;
            width: 40px;
            height: 40px;
            background-color: $blue;
            z-index: -2;
            @include borderRadius(50%);
            @include translateY(-50%);
            @include transitionAll(0.3s ease-in-out);
        }

        &:before {
            content: '';
            width: 40px;
            height: 40px;
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            z-index: 1;
            background-image: url('../../../inc/assets/long-arrow-right-creame.svg');
        }

        &:hover {
            &:after {
                width: 65px;
                height: 65px;
            }
        }
    }

    .swiper-button-next {
        right: -90px;
    }

    .swiper-button-prev {
        left: -90px;
        &:before {
            background-image: url('../../../inc/assets/long-arrow-left-creame.svg');
        }

        &:after {
            left: -20px;
            right: auto;
        }
    }

    .swiper-container {
        padding-bottom: 50px;
    }

    .swiper-pagination {
        width: auto;

        .swiper-pagination-bullet {
            width: 20px;
            height: 6px;
            @include borderRadius(3px);
            background-color: $sisal;
            opacity: 1;
            @include transitionAll(0.3s ease-in-out);

            &.swiper-pagination-bullet-active {
                width: 50px;
                background-color: $blue;
            }
        }
    }
}

#cutting-tech-gallery-block {
    padding-bottom: 0;
}



// 10. Testimonials Block
.testimonials-block {
    position: relative;
    padding: 97px 0 207px;
    overflow: hidden;

    .overlay {
        display: none;
    }	

    img {
        width: 80px;
        opacity: 0.5;
        margin-bottom: 45px;
    }

    .title {
        margin-bottom: 35px;
    }

    p {
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &.blue-background {
        background-color: $blue;
        color: white;

        .title {
            color: $sisal;
        }
    }

    &.white-background {
        background-color: white;
        color: $dark;

        .title {
            color: $dark;
        }
    }

    &.image-background {
        color: white;
        background-size: cover;
        background-position: center;

        .title {
            color: $sisal;
        }

        .overlay {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // background-color: rgba(0,0,0,0.7);
            z-index: 0;
        }

        .container {
            position: relative;
            z-index: 1;
        }
    }
}


// 11: Image and Form Block
.image-form-block {
    padding: 0;

    .image-block {
        background-size: cover;
        background-position: center;
        @include borderRadius(0 10px 10px 0);
        overflow: hidden;
    }

    .title {
        color: $blue;
        margin-bottom: 20px;
    }

    .form-block {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .wpcf7-response-output {
        color: $dark;
    }
}


// 12. Partners Logo Block
.partners-logos-block {
    padding: 75px 0;

    .title {
        color: $blue;
        margin-bottom: 80px;
    }

    .swiper-container {
        .swiper-slide {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    img {
        width: 100%;
    }
}


// 14. Image Testimonials
.image-testimonials-block {
    
    .image-block {
        background-size: cover;
        background-position: top center;
        overflow: hidden;
        position: relative;
        padding-top: 40%;
        background-repeat: no-repeat;
    }

    .title {
        margin-bottom: 60px;
        color: $blue;
    }

    .testimonial-content-block {
        padding-top: 50px;
        padding-bottom: 50px;
        position: relative;

        .quote-icon {
            width: 57px;
            margin-bottom: 40px;
            opacity: 0.5;
        }

        .quote {
            margin-bottom: 40px;
            color: $blue;
        }

        .author-name {
            color: $blue;
        }

        .job-title {
            color: $dark;
        }

        .btn {
            margin-top: 45px;
            display: inline-block;
        }
    }

    &.blue-background {
        background-color: $blue;
        color: white;

        .testimonial-content-block {
            .quote, .author-name {
                color: $sisal;
            }

            .job-title {
                color: white;
            }
        }
    }

    &.white-background {
        background-color: white;
        color: $dark;

        .title {
            color: $dark;
        }
    }

    .row {
        &.flex-sm-row-reverse {
            .image-block {
                // @include borderRadius(10px 0 0 10px);
            }

            .testimonial-content-block {
                padding-right: 6rem !important;
            }
        }

        &:not(.flex-sm-row-reverse) {
            .testimonial-content-block {
                padding-left: 6rem !important;
            }
        }
    }
}


// 13. Meet the Team Block
.team-block {
    padding: 75px 0;

    .title {
        color: $blue;
        margin-bottom: 40px;
    }

    .sub-title {
        margin-bottom: 40px;
        color: $blue;
    }

    .team-wrapper {
        .team-img {
            position: relative;
            margin-bottom: 40px;
            overflow: hidden;
            @include borderRadius(10px);

            img {
                position: absolute;
                top: 0;
                left: 0;
                @include transitionAll(0.5s ease-in-out);

                &.hover-image {
                    opacity: 0;
                }
            }
        }

        .title {
            color: $dark;
            margin-bottom: 10px;
            a {
                color: $dark;
            }
        }

        p {
            font-style: italic;
        }

        &:hover {
            .team-img {
                .hover-image {
                    // opacity: 1;
                }
            }
        }
    }

    .team-next-nav, .team-previous-nav {
        width: 40px;
        height: 40px;
        background-size: 100%;
        background-position: center;
        top: calc(50% - 80px);
        background-repeat: no-repeat;
        z-index: 1;
        @include translateY(-50%);

        &:focus {
            outline: none;
        }

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: -20px;
            width: 40px;
            height: 40px;
            @include borderRadius(50%);
            background-color: $blue;
            z-index: -2;
            @include translateY(-50%);
            @include transitionAll(0.3s ease-in-out);
        }

        &:before {
            content: '';
            width: 40px;
            height: 40px;
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            z-index: 1;
            background-image: url('../../../inc/assets/long-arrow-right-creame.svg');
        }

        &:hover {
            &:after {
                width: 65px;
                height: 65px;
            }
        }
    }

    .team-next-nav {
        right: -90px;
    }

    .team-previous-nav {
        left: -90px;
        &:before {
            background-image: url('../../../inc/assets/long-arrow-left-creame.svg');
        }

        &:after {
            left: -20px;
            right: auto;
        }
    }

    .swiper-container {
        padding-bottom: 50px;
    }

    .swiper-pagination {
        width: auto;

        .swiper-pagination-bullet {
            width: 20px;
            height: 6px;
            @include borderRadius(3px);
            background-color: $sisal;
            opacity: 1;
            @include transitionAll(0.3s ease-in-out);

            &.swiper-pagination-bullet-active {
                width: 50px;
                background-color: $blue;
            }
        }
    }
}


// News Block
.news-block {
    padding: 75px 0;

    .title {
        color: $blue;
        margin-bottom: 40px;
    }

    .swiper-slide {
        padding: 0 15px;
        height: 100%;
    }

    .news-next-nav, .news-previous-nav {
        width: 40px;
        height: 40px;
        background-size: 100%;
        background-position: center;
        top: calc(50% - 80px);
        background-repeat: no-repeat;
        z-index: 1;
        @include translateY(-50%);

        &:focus {
            outline: none;
        }

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: -20px;
            width: 40px;
            height: 40px;
            @include borderRadius(50%);
            background-color: $blue;
            z-index: -2;
            @include translateY(-50%);
            @include transitionAll(0.3s ease-in-out);
        }

        &:before {
            content: '';
            width: 40px;
            height: 40px;
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            z-index: 1;
            background-image: url('../../../inc/assets/long-arrow-right-creame.svg');
        }

        &:hover {
            &:after {
                width: 65px;
                height: 65px;
            }
        }
    }

    .news-next-nav {
        right: -90px;
    }

    .news-previous-nav {
        left: -90px;
        &:before {
            background-image: url('../../../inc/assets/long-arrow-left-creame.svg');
        }

        &:after {
            left: -20px;
            right: auto;
        }
    }

    .news-pagination {
        width: auto;

        .swiper-pagination-bullet {
            width: 20px;
            height: 6px;
            @include borderRadius(3px);
            background-color: $sisal;
            opacity: 1;
            @include transitionAll(0.3s ease-in-out);

            &.swiper-pagination-bullet-active {
                width: 50px;
                background-color: $blue;
            }
        }
    }

    .swiper-container {
        padding-bottom: 80px;
    }

    .view-all-btn {
        position: absolute;
        bottom: 15px;
        right: 0;
    }
}

.news-wrapper {
    background-color: white;
    padding: 40px 40px 80px;
    box-shadow: 0 8px 24px rgba(0,0,0,0.15);
    @include borderRadius(10px);
    height: 100%;
    position: relative;

    &:not(.news-recent-wrapper) {
        .image-wrapper {
            height: 170px;

            a {
                height: 100%;
            }

            img {
                object-fit: cover;
                height: 100%;
            }
        }
    }

    .image-wrapper {
        margin-bottom: 45px;

        a {
            display: block;
        }

    }

    .news-image {
        @include borderRadius(10px);
        overflow: hidden;
    }

    .date {
        display: inline-block;
        margin-bottom: 20px;
    }

    .news-title {
        margin-bottom: 20px;
        a {
            color: $blue;
        }
    }

    p {
        &:last-of-type {
            margin-bottom: 40px;
        }
    }

    .text-end {
        position: absolute;
        bottom: 40px;
        left: 40px;
        right: 40px;
    }

    &.news-recent-wrapper {
        margin-top: 40px;
        margin-bottom: 40px;

        .image-wrapper {
            width: 55.4%;
            margin: 0;
        }

        .content-wrapper {
            width: 44.6%;
            padding: 0 40px;

            .title {
                color: $blue;
                margin-bottom: 17px;
            }
            .date {
                margin-bottom: 5px;
            }
        }
    }
}

.news-list-section {
    padding-top: 75px;
    padding-bottom: 75px;

    .title-main {
        color: $blue;
        margin-bottom: 0;
    }

    .items {
        margin-top: 40px;
        
        .item {
            margin-top: 40px;
        }
    }
}

.post-content {
    padding-top: 225px;

    #crumbs {

        a {
            color: $blue;
            &:last-of-type {
                + .arrow {
                    &:after {
                        background-color: $blue;
                    }
                }
            }
        }

        .arrow {
            background-image: url('../../../inc/assets/long-arrow-right-blue.svg');
        }

        .current {
            color: $blue;
        }
    }

    .auther-name {
        font-style: italic;
        color: $blue;
        display: block;
        margin: 8px 0 40px;
    }

    .title-main {
        color: $blue;
        width: 60%;
        margin-bottom: 35px;
    }

    .full-img {
        margin-bottom: 85px;
    }

    img {
        @include borderRadius(10px !important);
    }

    .title {
        color: $blue;
        margin-bottom: 40px;
    }
    .wp-block-columns {
        margin-bottom: 30px;

        .wp-block-column {

            p {
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}

// Similar Posts
.similar-posts {
    padding: 75px 0;

    .title {
        color: $blue;
    }
}

// Careers Listing
.careers-listing {
    background-color: $sisal;
    position: relative;
    overflow: hidden;
    padding: 200px 0;

    &:after, &:before {
        content: '';
        width: 2px;
        height: 200px;
        background-color: white;
        position: absolute;
        @include rotate(30deg);
        z-index: 0;
    }

    &:after {
        bottom: -20px;
        left: 400px;
    }

    &:before {
        top: -20px;
        right: 410px;
    }

    .overlay {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../../inc/assets/circle-patterns.svg');
        background-position: center;
        background-position: center left -100px;
        background-repeat: no-repeat;
        opacity: 0.2;
        z-index: 0;
    }

    .title-main {
        color: $blue;
        margin-bottom: 40px;
    }

    p {
        margin-bottom: 40px;
    }

    .careers-list {
        padding: 0;
        margin: 0;

        li {
            padding: 29px 40px 26px;
            background-color: white;
            color: $blue;
            @include borderRadius(10px);

            &:nth-child(even) {
                background-color: $blue;
                color: white;

                .btn {
                    color: white;

                    &:after {
                        background-color: white;
                    }
                    &:hover {
                        color: $blue;
                    }
                }
            }

            .title {
                margin: 0;
                line-height: 24px;
            }
        }
    }
}

// single posts 
.single-careers {
    #crumbs {
        margin-bottom: 40px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        a {
            color: $blue;

            &:last-of-type {
                + .arrow {
                    background-image: url('../../../inc/assets/long-arrow-right-creame.svg');

                    &:after {
                        background-color: $blue;
                    }
                }
            }
        }

        .arrow {
            background-image: url('../../../inc/assets/long-arrow-right-blue.svg');
        }

        .current {
            color: $blue;
        }
    }
}
.career-single-post {
    padding: 200px 0 80px;

    .title-main {
        color: $blue;
        margin: 40px 0 25px;
    }
    .single-post-left {
        padding-right: 17px;

        .wp-block-table {
            background: rgba(196, 187, 158, .10);
            padding: 19px 30px 16px;
            margin-bottom: 40px;
            @include borderRadius(10px);

            table {
                tr {
                    border-bottom: 1px solid rgba(19,33,106,.10);

                    &:last-child {
                        border: none; 

                        td {
                            padding-bottom: 0;
                        }
                    }
                    &:first-child {
                        td {
                            padding-top: 0;
                        }
                    }

                    td {
                        padding: 12px 0 13px;

                        strong {
                            font-size: 18px;
                            padding-right: 10px;
                            color: $blue;
                        }

                        &:last-child {
                            width: 150px;
                        }

                        &:nth-child(2) {
                            width: 206px;
                        }
                    }
                }
            }
        }

        .title {
            margin: 40px 0 40px;
        }

        .list-style {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                padding-left: 35px;
                margin-bottom: 10px;
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    top: 4px;
                    left: 0;
                    width: 12px;
                    height: 12px;
                    background-color: $blue;
                    @include borderRadius(50%);
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .btn {
            margin-top: 40px;
        }
    }

    .single-post-right {
        padding-left: 43px;

        .wpcf7 {
            position: relative;
            padding: 40px 32px;
            background-color: $blue;
            @include borderRadius(10px);

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: url('../../../inc/assets/circle-patterns.svg');
                background-size: cover;
                background-position: left -210px top -5px;
                background-repeat: no-repeat;
                opacity: 0.1;
                z-index: 0;
            }

            form {
                position: relative;
                overflow: hidden;

                .title {
                    color: white;
                    margin-bottom: 30px;
                }

                label {
                    color: white;
                }

                input {
                    background-color: white;
                }

                input[type=submit] {
                    background-color: $sisal;
                }

                input[type=checkbox] {
                    width: 20px;
                    height: 20px;
                }

                .cv-wrapper {
                    display: inline-block;
                    position: relative;
                    width: auto;
                    cursor: pointer;
                    margin-bottom: 25px;

                    .wpcf7-form-control-wrap {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 5;
                        background: 0 0;
                        color: #fff;
                        padding: 15px 35px;
                        display: inline-block;
                        cursor: pointer;
                    }

                    input[type=file] {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        cursor: pointer;
                    }

                    span {
                        position: relative;
                        z-index: 1;
                        color: white;
                        padding: 15px 35px;
                        display: inline-block;
                        cursor: pointer;
                        background: #030304;
                        cursor: pointer;
                        @include borderRadius(28px);
                    }

                    .wpcf7-not-valid-tip {
                        width: 230%;
                        padding: 0;
                        position: absolute;
                        left: 10px;
                        bottom: -25px;
                        color: red;
                        background-color: transparent;
                        font-size: 13px;
                    }

                    .wpcf7-response-output {
                        font-size: 13px;
                    }
                }

                .wpcf7-checkbox {
                    .wpcf7-list-item {
                        margin-top: 20px;
                    }
                }

                .cv-input-wrapper p {
                    display: inline-block;
                    margin-left: 10px;
                    color: white;
                }
            }
        }
    }
}

// Contact Page
.contact-page {
    #crumbs {
        z-index: 1;

        .arrow {

            &:after {
                background-color: white;
            }
        }

        .current {
            color: white;
        }
    }
}
.contact-form {
    padding: 75px 0;
    background-color: $blue;

    .title {
        color: white;
        margin-bottom: 20px;
    }

    .contact-content {
        color: white;
        margin-bottom: 72px;
        padding-right: 4rem;

        img {
            width: auto;
            height: 40px;
            margin-bottom: 40px;
        }

        .title {
            color: white;
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 5px;
        }

        a {
            color: white;
        }
    }

    .wpcf7-form {
        margin-bottom: 50px;

        label {
            color: white;
        }

        input, select, textarea {
            background-color: white;
        }

        input[type="submit"] {
            background-color: $sisal;
        }
    }

    iframe {
        margin-top: 100px;
    }
}

// Pagination 
.paging-navigation {
    .nav-links {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-top: 80px;

        .nav-previous {

            .btn-expand {
                padding-right: 85px;

                &:before {
                    content: "Previous";
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    margin-top: 2px;
                    @include translateY(-50%);
                }

                &:after {
                    left: 0;
                    right: inherit;
                }

                img {
                    margin: 0 17px 0 0;
                }
            }
        }

        .nav-next {

            .btn-expand {
                padding-left: 55px;

                &:before {
                    content: "Next";
                    position: absolute;
                    top: 50%;
                    left: 15px;
                    margin-top: 2px;
                    @include translateY(-50%);
                }
            }
        }
    }
}

// Home Page
.home {
    .news-block {

        .news-wrapper {
            // margin-top: 50px;
        }
    }

    .hero-banner-block-homepage {
        +.content-with-image-block {
            .image-block {
                @include borderRadius(0);
            }
        }
    }
}

// About Us Page
.about-us-page {

    .content-with-image-block {

        +.core-values-block {
            // margin-top: 100px;
        }
    }

    .team-block {

        +.team-block {
            padding-top: 0;
        }
    }
}

// Investment Opportunities Page
.investment-opportunities-page {

    .testimonials-block {
        margin: 100px 0;
    }
}

// News Page
.news-page {
    .news-wrapper {
        margin-bottom: 40px;
    }
}

// 404 Page
.not-found {
    padding-top: 125px;

    .not-found-content {
        height: 600px;

        h1 {
            font-size: 80px;
            margin-bottom: 30px;
        }

        span {
            display: block;
            margin-bottom: 25px;
        }

        .btn-expand {

            &:after {
                left: 0;
                right: inherit;
            }

            img {
                margin: 0 17px 0 0;
            }
        }
    }
}

.similar-posts {
    .title {
        margin-bottom: 40px;
    }
}

// Search Page
.search-block {
    padding: 200px 0 100px;

    .serach-title {
        margin-bottom: 30px;
    }
}

// Meet the Team
.meet-the-team-list-section {
    padding: 75px 0;

    .title-main {
        color: $blue;
        margin-bottom: 0;
    }

    .team-members {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .team-member-wrapper {
        position: relative;
        border-radius: 10px;
        overflow: hidden;
        height: 400px;
        margin-top: 20px;
        margin-bottom: 20px;
        box-shadow: 0 8px 24px rgba(0,0,0,0.15);

        a {
            color: black;
            height: 100%;

            .image-wrapper {
                height: 100%;

                .casual-photo {
                    opacity: 1;
                }

                .professional-photo {
                    opacity: 0;
                }

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    transition: opacity 0.5s ease-in-out;
                    object-fit: cover;
                }

                &:before {
                    transition: opacity 0.5s ease-in-out;
                    content: "";
                    background-color: rgba(white, 0.5);
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 10;
                    opacity: 0;
                }
            }

            .content-wrapper {
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 2rem;
                z-index: 10;
                opacity: 0;
                transition: opacity 0.5s ease-in-out;

                .team-member-title {
                    small {
                        display: block;
                        font-weight: normal;
                    }
                }
            }

            &:hover {
                .image-wrapper {
                    .casual-photo {
                        opacity: 1;
                    }

                    .professional-photo {
                        opacity: 0;
                    }

                    &:before {
                        opacity: 1;
                    }
                }

                .content-wrapper {
                    opacity: 1;
                }
            }
        }
    }
}

.teammembers-single-post {
    padding: 200px 0 80px;

    #crumbs {
        margin-bottom: 40px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        a {
            color: $blue;
            &:last-of-type {
                + .arrow {
                    background-image: url('../../../inc/assets/long-arrow-right-creame.svg');

                    &:after {
                        background-color: $blue;
                    }
                }
            }
        }

        .arrow {
            background-image: url('../../../inc/assets/long-arrow-right-blue.svg');
        }

        .current {
            color: $blue;
        }
    }

    .title-main {
        color: $blue;
        margin: 40px 0 25px;
    }

    .single-post-left {
        padding-right: 17px;

        .contact-details {
            list-style: none;
            padding-left: 0;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        .social-links {
            display: flex;
            list-style: none;
            padding-left: 0;
            margin-top: 10px;
            margin-bottom: 20px;

            li {
                &:not(:last-child) {
                    margin-right: 10px;
                }
                
                a {
                    width: 24px;
                    height: 24px;
                    @include flex;
                    @include justify-content(center);
                    @include align-items(center);
                    border-radius: 100%;
                    background-color: $dark-blue;
                    padding: 5px;

                    img {
                        width: 10px;
                        height: auto;
                    }
                }
            }
        }

        .wp-block-table {
            background: rgba(196, 187, 158, .10);
            padding: 19px 30px 16px;
            margin-bottom: 40px;
            @include borderRadius(10px);

            table {
                tr {
                    border-bottom: 1px solid rgba(19,33,106,.10);

                    &:last-child {
                        border: none; 

                        td {
                            padding-bottom: 0;
                        }
                    }
                    &:first-child {
                        td {
                            padding-top: 0;
                        }
                    }

                    td {
                        padding: 12px 0 13px;

                        strong {
                            font-size: 18px;
                            padding-right: 10px;
                            color: $blue;
                        }

                        &:last-child {
                            width: 150px;
                        }

                        &:nth-child(2) {
                            width: 206px;
                        }
                    }
                }
            }
        }

        .title {
            margin: 40px 0 40px;
        }

        .list-style {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                padding-left: 35px;
                margin-bottom: 10px;
                position: relative;

                &:before {
                    content: "";
                    position: absolute;
                    top: 4px;
                    left: 0;
                    width: 12px;
                    height: 12px;
                    background-color: $blue;
                    @include borderRadius(50%);
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .btn {
            margin-top: 40px;
        }
    }

    .single-post-right {
        padding-left: 43px;

        .wpcf7 {
            position: relative;
            padding: 40px 32px;
            background-color: $blue;
            @include borderRadius(10px);

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-image: url('../../../inc/assets/circle-patterns.svg');
                background-size: cover;
                background-position: left -210px top -5px;
                background-repeat: no-repeat;
                opacity: 0.1;
                z-index: 0;
            }

            form {
                position: relative;
                overflow: hidden;

                .title {
                    color: white;
                    margin-bottom: 30px;
                }

                label {
                    color: white;
                }

                input {
                    background-color: white;
                }

                input[type=submit] {
                    background-color: $sisal;
                }

                input[type=checkbox] {
                    width: 20px;
                    height: 20px;
                }

                .cv-wrapper {
                    display: inline-block;
                    position: relative;
                    width: auto;
                    cursor: pointer;
                    margin-bottom: 25px;

                    .wpcf7-form-control-wrap {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 5;
                        background: 0 0;
                        color: #fff;
                        padding: 15px 35px;
                        display: inline-block;
                        cursor: pointer;
                    }

                    input[type=file] {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        cursor: pointer;
                    }

                    span {
                        position: relative;
                        z-index: 1;
                        color: white;
                        padding: 15px 35px;
                        display: inline-block;
                        cursor: pointer;
                        background: #030304;
                        cursor: pointer;
                        @include borderRadius(28px);
                    }

                    .wpcf7-not-valid-tip {
                        width: 230%;
                        padding: 0;
                        position: absolute;
                        left: 10px;
                        bottom: -25px;
                        color: red;
                        background-color: transparent;
                        font-size: 13px;
                    }

                    .wpcf7-response-output {
                        font-size: 13px;
                    }
                }

                .wpcf7-checkbox {
                    .wpcf7-list-item {
                        margin-top: 20px;
                    }
                }

                .cv-input-wrapper p {
                    display: inline-block;
                    margin-left: 10px;
                    color: white;
                }
            }
        }
    }
}

.gallery-block {
    .button-group {
        display: inline-block;
    }

    .orbit {
        .orbit-wrapper {
            .orbit-controls {
                .orbit-previous, .orbit-next {
                    background-color: $blue;
                    width: 60px;
                    height: 60px;
                    border-radius: 4px;

                    &:hover {
                        background-color: $dark;
                        cursor: pointer;
                    }

                    svg {
                        width: 27px;
                        margin-top: -2px;
                    }
                }

                .orbit-previous {
                    left: -30px;
                }

                .orbit-next {
                    right: -30px;
                }
            }
        }
    }

    .gallery-slider {
        margin-top: 2rem;
        margin-bottom: 2rem;

        .slide {
            > .row {
                margin-left: -7.5px;
                margin-right: -7.5px;
            }
        }

        .inner-slide-content {
            position: relative;
            height: 100vh;
            min-height: 250px;
            max-height: 600px;

            .row {
                margin-left: -7.5px;
                margin-right: -7.5px;
            }

            .col {
                padding-left: 7.5px;
                padding-right: 7.5px;
                background-clip: content-box;
            }

            .background {
                height: 100%;

                a {
                    border-radius: 4px;
                    overflow: hidden;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                    width: 100%;
                    height: 100%;
                    display: block;
                    outline: none;
                }
            }

            .background-image-column {
                height: 100%;

                // &.first {
                //  padding-left: 0;
                // }

                // &.last {
                //  padding-right: 0;
                // }

                &.full-width {
                    
                }

                .row {
                    height: 100%;
                }

                .background {
                    &.background-50h {
                        height: calc(50% - (7.5px));

                        &:first-of-type {
                            margin-bottom: calc(7.5px * 2);
                        }
                    }
                }

                // &.w50 {
                //  &:nth-child(1) {
                //      padding-left: 0;
                //  }

                //  &:nth-child(2) {
                //      padding-right: 0;
                //  }
                // }
            }


            &.images-5 {
                .single {

                }

                .grid {
                    .columns {
                        
                    }
                }
            }
        }

        .slick-track {
            margin: 0;
        }

        // .slick-slide {
        //  outline: none;
        //     margin: 0 1rem;
        // }

        // .slick-list {
        //  margin: 0 -1rem;
        // }
    }
}

.archive {
    .news-list-section {
        padding-top: 200px;

        .items {
            margin-top: 0;
        }
    }    
}
