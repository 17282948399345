.btn, button{
  display: inline-block;
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;

  &:hover {
  	color: white;
  }

  &:focus {
  	outline: none;
  	box-shadow: none;
  }
}

.btn.btn-expand {
	position: relative;
	padding: 0 15px;
	z-index: 5;

	img {
		width: auto;
		margin-left: 17px;
		margin-bottom: 0;
	}

	&:after {
		content: '';
		position: absolute;
		top: 50%;
		right: 0;
		height: 30px;
		width: 30px;
		border-radius: 50px;
		z-index: -1;
		@include translateY(-50%);
		@include transitionAll(0.3s ease-in-out);
	}

	&:hover {
		&:after {
			width: 100%;
			height: 40px;
		}
	}
}

.btn-white {
	color: white;

	&:after {
		background-color: $sisal;
	}
}

.btn-blue {
	color: $blue;

	&:after {
		background-color: $blue;
	}
}

.btn-blue-sisal {
	color: $blue;

	&:after {
		background-color: $sisal;
	}
}