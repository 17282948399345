/*================ Global ================*/
html {
  font-family: $primary-font;
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
}

body {
  font-family: $primary-font;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
}

hr {
  border: 0;
  border-top: 1px solid #eee;
}

a {
  display: inline-block;
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

.container {
  @include responsive(medium-desktop, min) {
    max-width: 1210px;
  }
}

p {
  margin-bottom: 25px;
  &:empty {
      display: none;
  }
}