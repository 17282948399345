input, textarea {
  display: inline-block;
  width: 100%;
  padding: 14px 20px;
  border: 0;
  border-radius: 10px;
  background-color: rgba(19,33,106,0.04);
  color: #030304;
  margin-bottom: 20px;

  &::placeholder {
    color: #030304;
    opacity: 0.5;
  }

  &:-ms-input-placeholder {
    color: #030304;
    opacity: 0.5;
  }

  &::-ms-input-placeholder {
    color: #030304;
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }
}

select {
  display: inline-block;
  width: 100%;
  padding: 14px 20px;
  border: 0;
  border-radius: 10px;
  background-color: rgba(19,33,106,0.04);
  color: #030304;
  margin-bottom: 20px;
  -webkit-appearance: none;
  background-image: url('../inc/assets/angle-down-regular.svg');
  background-position: center right 15px;
  background-repeat: no-repeat;
  background-size: 14px;
  outline: none;

  &::placeholder {
    color: #030304;
    opacity: 0.5;
  }

  &:-ms-input-placeholder {
    color: #030304;
    opacity: 0.5;
  }

  &::-ms-input-placeholder {
    color: #030304;
    opacity: 0.5;
  }
}

form {
  p {
    &:empty {
      display: none;
    }
  }

  label {
    display: block;
    font-weight: 700;
    color: $dark;
    margin-bottom: 13px;
  }
}

.wpcf7-checkbox {
  label {
    display: flex;
    margin-bottom: 33px;
  }

  input[type="checkbox"] {
    width: auto;
    margin-right: 10px;
    margin-bottom: 0;
  }

  span {
    line-height: 14px;
    font-size: 14px;
    font-weight: 400;
    margin-left: 0;
  }
}

input[type="submit"] {
  background-color: $sisal;
  color: $blue;
  font-weight: 700;
  border-radius: 28px;
  margin-bottom: 0; 
  @include transitionAll(0.3s ease-in-out);

  &:hover {
    background-color: $blue;
    color: white;
  }
}

.input-btn {
  .ajax-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    @include translateX(-50%);
    @include translateY(-50%);
  }
}

.wpcf7-response-output {
    color: white;
}