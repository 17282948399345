/*================ Modules ================*/
/*================ Font Variables ================*/
@use "sass:map";
/*** mixins.scss ***/
/*================ Components ================*/
/*================ Global ================*/
html {
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
}

hr {
  border: 0;
  border-top: 1px solid #eee;
}

a {
  display: inline-block;
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

@media (min-width: 1550px) {
  .container {
    max-width: 1210px;
  }
}

p {
  margin-bottom: 25px;
}

p:empty {
  display: none;
}

.btn, button {
  display: inline-block;
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
}

.btn:hover, button:hover {
  color: white;
}

.btn:focus, button:focus {
  outline: none;
  box-shadow: none;
}

.btn.btn-expand {
  position: relative;
  padding: 0 15px;
  z-index: 5;
}

.btn.btn-expand img {
  width: auto;
  margin-left: 17px;
  margin-bottom: 0;
}

.btn.btn-expand:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  height: 30px;
  width: 30px;
  border-radius: 50px;
  z-index: -1;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.btn.btn-expand:hover:after {
  width: 100%;
  height: 40px;
}

.btn-white {
  color: white;
}

.btn-white:after {
  background-color: #C4BB9E;
}

.btn-blue {
  color: #13216A;
}

.btn-blue:after {
  background-color: #13216A;
}

.btn-blue-sisal {
  color: #13216A;
}

.btn-blue-sisal:after {
  background-color: #C4BB9E;
}

input, textarea {
  display: inline-block;
  width: 100%;
  padding: 14px 20px;
  border: 0;
  border-radius: 10px;
  background-color: rgba(19, 33, 106, 0.04);
  color: #030304;
  margin-bottom: 20px;
}

input::placeholder, textarea::placeholder {
  color: #030304;
  opacity: 0.5;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #030304;
  opacity: 0.5;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  color: #030304;
  opacity: 0.5;
}

input:focus, textarea:focus {
  outline: none;
}

select {
  display: inline-block;
  width: 100%;
  padding: 14px 20px;
  border: 0;
  border-radius: 10px;
  background-color: rgba(19, 33, 106, 0.04);
  color: #030304;
  margin-bottom: 20px;
  -webkit-appearance: none;
  background-image: url("../inc/assets/angle-down-regular.svg");
  background-position: center right 15px;
  background-repeat: no-repeat;
  background-size: 14px;
  outline: none;
}

select::placeholder {
  color: #030304;
  opacity: 0.5;
}

select:-ms-input-placeholder {
  color: #030304;
  opacity: 0.5;
}

select::-ms-input-placeholder {
  color: #030304;
  opacity: 0.5;
}

form p:empty {
  display: none;
}

form label {
  display: block;
  font-weight: 700;
  color: #151515;
  margin-bottom: 13px;
}

.wpcf7-checkbox label {
  display: flex;
  margin-bottom: 33px;
}

.wpcf7-checkbox input[type="checkbox"] {
  width: auto;
  margin-right: 10px;
  margin-bottom: 0;
}

.wpcf7-checkbox span {
  line-height: 14px;
  font-size: 14px;
  font-weight: 400;
  margin-left: 0;
}

input[type="submit"] {
  background-color: #C4BB9E;
  color: #13216A;
  font-weight: 700;
  border-radius: 28px;
  margin-bottom: 0;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

input[type="submit"]:hover {
  background-color: #13216A;
  color: white;
}

.input-btn .ajax-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.wpcf7-response-output {
  color: white;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(../../fonts/poppins/pxiAyp8kv8JHgFVrJJLmE0tDMPKhSkFEkm8.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(../../fonts/poppins/pxiAyp8kv8JHgFVrJJLmE0tMMPKhSkFEkm8.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(../../fonts/poppins/pxiAyp8kv8JHgFVrJJLmE0tCMPKhSkFE.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(../../fonts/poppins/pxiDyp8kv8JHgFVrJJLmv1pVFteOYktMqlap.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(../../fonts/poppins/pxiDyp8kv8JHgFVrJJLmv1pVGdeOYktMqlap.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(../../fonts/poppins/pxiDyp8kv8JHgFVrJJLmv1pVF9eOYktMqg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(../../fonts/poppins/pxiDyp8kv8JHgFVrJJLm21lVFteOYktMqlap.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(../../fonts/poppins/pxiDyp8kv8JHgFVrJJLm21lVGdeOYktMqlap.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(../../fonts/poppins/pxiDyp8kv8JHgFVrJJLm21lVF9eOYktMqg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(../../fonts/poppins/pxiDyp8kv8JHgFVrJJLmg1hVFteOYktMqlap.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(../../fonts/poppins/pxiDyp8kv8JHgFVrJJLmg1hVGdeOYktMqlap.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(../../fonts/poppins/pxiDyp8kv8JHgFVrJJLmg1hVF9eOYktMqg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(../../fonts/poppins/pxiDyp8kv8JHgFVrJJLmr19VFteOYktMqlap.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(../../fonts/poppins/pxiDyp8kv8JHgFVrJJLmr19VGdeOYktMqlap.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(../../fonts/poppins/pxiDyp8kv8JHgFVrJJLmr19VF9eOYktMqg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(../../fonts/poppins/pxiDyp8kv8JHgFVrJJLmy15VFteOYktMqlap.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(../../fonts/poppins/pxiDyp8kv8JHgFVrJJLmy15VGdeOYktMqlap.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(../../fonts/poppins/pxiDyp8kv8JHgFVrJJLmy15VF9eOYktMqg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(../../fonts/poppins/pxiDyp8kv8JHgFVrJJLm111VFteOYktMqlap.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(../../fonts/poppins/pxiDyp8kv8JHgFVrJJLm111VGdeOYktMqlap.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(../../fonts/poppins/pxiDyp8kv8JHgFVrJJLm111VF9eOYktMqg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(../../fonts/poppins/pxiDyp8kv8JHgFVrJJLm81xVFteOYktMqlap.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(../../fonts/poppins/pxiDyp8kv8JHgFVrJJLm81xVGdeOYktMqlap.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(../../fonts/poppins/pxiDyp8kv8JHgFVrJJLm81xVF9eOYktMqg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(../../fonts/poppins/pxiGyp8kv8JHgFVrLPTucXtAOvWDSHFF.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(../../fonts/poppins/pxiGyp8kv8JHgFVrLPTufntAOvWDSHFF.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(../../fonts/poppins/pxiGyp8kv8JHgFVrLPTucHtAOvWDSA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../../fonts/poppins/pxiByp8kv8JHgFVrLFj_Z11lFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../../fonts/poppins/pxiByp8kv8JHgFVrLFj_Z1JlFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(../../fonts/poppins/pxiByp8kv8JHgFVrLFj_Z1xlFd2JQEk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../../fonts/poppins/pxiByp8kv8JHgFVrLDz8Z11lFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../../fonts/poppins/pxiByp8kv8JHgFVrLDz8Z1JlFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../../fonts/poppins/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../../fonts/poppins/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../../fonts/poppins/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../../fonts/poppins/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../../fonts/poppins/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../../fonts/poppins/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(../../fonts/poppins/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../../fonts/poppins/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../../fonts/poppins/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../../fonts/poppins/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../../fonts/poppins/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../../fonts/poppins/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../../fonts/poppins/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(../../fonts/poppins/pxiByp8kv8JHgFVrLDD4Z11lFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(../../fonts/poppins/pxiByp8kv8JHgFVrLDD4Z1JlFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(../../fonts/poppins/pxiByp8kv8JHgFVrLDD4Z1xlFd2JQEk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(../../fonts/poppins/pxiByp8kv8JHgFVrLBT5Z11lFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(../../fonts/poppins/pxiByp8kv8JHgFVrLBT5Z1JlFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(../../fonts/poppins/pxiByp8kv8JHgFVrLBT5Z1xlFd2JQEk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

h1, h2, h3, h4, h5, h6,
.h-1, .h-2, .h-3, .h-4, .h-5, .h-6 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

h1, .h-1 {
  font-size: 42px;
  line-height: 48px;
}

h2, .h-2 {
  font-size: 32px;
  line-height: 34px;
}

h3, .h-3 {
  font-size: 26px;
  line-height: 30px;
}

h4, .h-4 {
  font-size: 22px;
  line-height: 30px;
}

h5, .h-5 {
  font-size: 20px;
  line-height: 25px;
}

h6, .h-6 {
  font-size: 18px;
  line-height: 25px;
}

small, .small {
  font-size: 14px;
  line-height: 20px;
}

@media (max-width: 1440px) {
  h1, .h-1 {
    font-size: 39.9px;
    line-height: 48px;
  }
  h2, .h-2 {
    font-size: 30.4px;
    line-height: 34px;
  }
  h3, .h-3 {
    font-size: 24.7px;
    line-height: 30px;
  }
  h4, .h-4 {
    font-size: 20.9px;
    line-height: 30px;
  }
  h5, .h-5 {
    font-size: 19px;
    line-height: 25px;
  }
  h6, .h-6 {
    font-size: 17.1px;
    line-height: 25px;
  }
}

@media (max-width: 1199px) {
  h1, .h-1 {
    font-size: 37.8px;
    line-height: 45px;
  }
  h2, .h-2 {
    font-size: 28.8px;
    line-height: 34px;
  }
  h3, .h-3 {
    font-size: 23.4px;
    line-height: 30px;
  }
  h4, .h-4 {
    font-size: 19.8px;
    line-height: 25px;
  }
  h5, .h-5 {
    font-size: 18px;
    line-height: 25px;
  }
  h6, .h-6 {
    font-size: 16.2px;
    line-height: 25px;
  }
}

@media (max-width: 991px) {
  h1, .h-1 {
    font-size: 33.6px;
    line-height: 40px;
  }
  h2, .h-2 {
    font-size: 25.6px;
    line-height: 30px;
  }
  h3, .h-3 {
    font-size: 20.8px;
    line-height: 28px;
  }
  h4, .h-4 {
    font-size: 17.6px;
    line-height: 25px;
  }
  h5, .h-5 {
    font-size: 16px;
    line-height: 25px;
  }
  h6, .h-6 {
    font-size: 14.4px;
    line-height: 22px;
  }
  p, body {
    font-size: 12.8px;
    line-height: 18px;
  }
}

@media (max-width: 767px) {
  h1, .h-1 {
    font-size: 21px;
    line-height: 30px;
  }
  h2, .h-2 {
    font-size: 17px;
    line-height: 20px;
  }
  h3, .h-3 {
    font-size: 16.9px;
    line-height: 25px;
  }
  h4, .h-4 {
    font-size: 14.3px;
    line-height: 22px;
  }
  h5, .h-5 {
    font-size: 14px;
    line-height: 20px;
  }
  h6, .h-6 {
    font-size: 12.8px;
    line-height: 20px;
  }
}

/*================ Layouts ================*/
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #13216A;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  z-index: 15;
}

header .navbar {
  padding: 0 60px;
}

header .navbar .navbar-brand img {
  width: 80%;
}

header ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

header .contact-btn {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

header .mobile-contact-btn {
  display: none;
}

header .navigation-links .menu-item {
  position: relative;
}

header .navigation-links .menu-item .menu-image-hover-wrapper {
  margin: 0;
  padding: 0;
}

header .navigation-links .menu-item .menu-image-hover-wrapper img {
  width: 40px;
  padding-right: 0;
  margin-right: 18px;
}

header .navigation-links .menu-item .menu-image-hover-wrapper .hovered-image {
  display: none;
}

header .navigation-links .menu-item .menu-image-hover-wrapper .menu-image {
  display: inline-block;
}

header .navigation-links .menu-item .menu-image-title {
  padding-left: 0;
}

header .navigation-links .menu-item:hover > a {
  background-color: white;
  color: #13216A;
}

header .navigation-links .menu-item:hover .mobile-menu-icon span {
  border-color: #0C174F;
}

header .navigation-links .menu-item > a {
  color: white;
  padding: 50px 20px;
  font-weight: 700;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

header .navigation-links .menu-item .mobile-menu-icon {
  display: none;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 15px;
  right: 15px;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

header .navigation-links .menu-item .mobile-menu-icon span {
  border-style: solid;
  border-width: 2px 2px 0 0;
  border-color: #fff;
  height: 7px;
  width: 7px;
  padding: 3px;
  -moz-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

header .navigation-links .menu-item.menu-item-has-children:hover > a:after {
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

header .navigation-links .menu-item.menu-item-has-children:hover .sub-menu {
  opacity: 1;
  visibility: visible;
}

header .navigation-links .menu-item.menu-item-has-children > a {
  position: relative;
}

header .navigation-links .menu-item.menu-item-has-children > a:after {
  content: '';
  display: inline-block;
  border-style: solid;
  border-width: 2px 2px 0 0;
  height: 7px;
  width: 7px;
  margin-left: 8px;
  vertical-align: middle;
  line-height: normal;
  -moz-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

header .navigation-links .menu-item.current-menu-item > a, header .navigation-links .menu-item.current-page-ancestor > a {
  background-color: white;
  color: #13216A;
}

header .navigation-links .menu-item.show .sub-menu {
  height: auto;
}

header .navigation-links .sub-menu {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: 100%;
  left: 0;
  width: 342px;
  background-color: white;
  z-index: 2;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

header .navigation-links .sub-menu li > a {
  display: block;
  color: #13216A;
  padding: 30px 40px;
  border-radius: 10px;
}

header .navigation-links .sub-menu li > a:hover {
  color: white;
  background-color: #13216A;
}

header .navigation-links .sub-menu li > a:hover span {
  color: white;
}

header .navigation-links .sub-menu li > a span {
  color: #13216A;
}

header .navigation-links .sub-menu li.current-menu-item > a {
  background-color: #13216A;
  color: white;
}

header .navigation-links .sub-menu li.current-menu-item > a span {
  color: white;
}

header .navigation-links .sub-menu li.current-menu-item .menu-image-hover-wrapper .menu-image {
  display: none;
}

header .navigation-links .sub-menu li.current-menu-item .menu-image-hover-wrapper .hovered-image {
  display: inline-block;
  opacity: 1;
}

header .navigation-links .sub-menu li:hover .menu-image-hover-wrapper .hovered-image {
  display: inline-block;
}

header .navigation-links .sub-menu li:hover .menu-image-hover-wrapper .menu-image {
  display: none;
}

.admin-bar header {
  top: 32px;
}

.contact-page header {
  background-color: #13216A;
}

#crumbs {
  margin-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

#crumbs a {
  color: white;
  margin-bottom: 20px;
}

#crumbs a:hover + .arrow:after {
  width: 20px;
  height: 20px;
}

#crumbs a:last-of-type + .arrow {
  background-image: url("../../../inc/assets/long-arrow-right-creame.svg");
}

#crumbs a:last-of-type + .arrow:after {
  background-color: white;
}

#crumbs span {
  display: inline-block;
  margin-bottom: 20px;
}

#crumbs .arrow {
  background-image: url("../../../inc/assets/long-arrow-right-light.svg");
  width: 18px;
  height: 18px;
  background-size: 100%;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 25px 20px 20px;
  position: relative;
}

#crumbs .arrow:after {
  content: '';
  width: 14px;
  height: 14px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-color: #C4BB9E;
  position: absolute;
  top: 50%;
  right: -5px;
  z-index: -1;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.hero-banner-block-homepage {
  position: relative;
  background-size: cover !important;
  background-position: center center !important;
  overflow: hidden;
  background-blend-mode: darken;
  transition: 3s;
}

.hero-banner-block-homepage .carousel {
  position: absolute;
  width: 100%;
}

.hero-banner-block-homepage .carousel .carousel-inner {
  height: 100%;
}

.hero-banner-block-homepage .carousel .carousel-inner .carousel-item {
  height: 100%;
}

.hero-banner-block-homepage .carousel .carousel-inner .carousel-item img {
  height: 100%;
  min-width: 100%;
  object-fit: cover;
  object-position: center;
}

.hero-banner-block-homepage .overlay:not(.image-overlay) {
  background: rgba(0, 0, 0, 0.75);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
}

.hero-banner-block-homepage .row {
  min-height: 75vh;
  color: white;
}

.hero-banner-block-homepage .row.flex-row-reverse {
  position: relative;
}

.hero-banner-block-homepage .row.flex-row-reverse .title-col {
  margin-top: 0;
}

.hero-banner-block-homepage .row.flex-row-reverse:after {
  position: absolute;
  bottom: -50px;
  left: 180px;
  content: '';
  width: 2px;
  height: 300px;
  background: white;
  -moz-transform: rotate(40deg);
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
}

.hero-banner-block-homepage .title-col {
  margin-top: 150px;
  margin-bottom: 80px;
}

.hero-banner-block-homepage .title-col .title-main {
  margin-bottom: 15px;
}

.hero-banner-block-homepage .container {
  position: relative;
  z-index: 1;
}

.overlay:not(.image-overlay) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.explore-btn {
  position: absolute;
  bottom: 60px;
  left: 50%;
  font-size: 16px;
  line-height: 20px;
  color: white;
  font-weight: 400;
  text-align: center;
  z-index: 2;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.explore-btn:hover {
  color: #fff;
}

.explore-btn:hover .arrow:after {
  width: 45px;
  height: 45px;
}

.explore-btn .arrow {
  position: relative;
  display: inline-block;
  margin-top: 25px;
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.explore-btn .arrow img {
  width: 25px;
  display: block;
  position: relative;
  z-index: 1;
}

.explore-btn .arrow:after {
  content: '';
  position: absolute;
  top: 50%;
  right: -10px;
  width: 27px;
  height: 27px;
  background-color: #C4BB9E;
  -webkit-border-radius: 27px;
  -moz-border-radius: 27px;
  -ms-border-radius: 27px;
  border-radius: 27px;
  z-index: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.hero-banner-block-subpage {
  position: relative;
  background-size: cover;
  background-position: center center;
  overflow: hidden;
  color: white;
}

.hero-banner-block-subpage .overlay:not(.image-overlay) {
  background: rgba(0, 0, 0, 0.75);
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
}

.hero-banner-block-subpage .container {
  position: relative;
  z-index: 1;
}

.hero-banner-block-subpage .container .row {
  min-height: 75vh;
  padding-top: 200px;
  padding-bottom: 150px;
}

.hero-banner-block-subpage .title {
  margin-bottom: 15px;
}

.hero-banner-block-subpage .brand-logos {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-left: -1rem;
  margin-right: -1rem;
}

.hero-banner-block-subpage .brand-logos .brand-logo {
  padding-left: 1rem;
  padding-right: 1rem;
}

.hero-banner-block-subpage .brand-logos .brand-logo img {
  max-width: 120px;
}

.content-with-image-block {
  padding: 0;
}

.content-with-image-block .image-block {
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.content-with-image-block .image-block .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.content-with-image-block .image-block .overlay:not(.image-overlay) {
  background-color: rgba(0, 0, 0, 0.6);
}

.content-with-image-block .image-block .overlay.image-overlay {
  opacity: 0.25;
}

.content-with-image-block .image-block .overlay.bottom-right-corner {
  background-size: 200px;
  background-position: bottom 40px right 40px;
  background-repeat: no-repeat;
}

.content-with-image-block .image-block .overlay.cover-image {
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center center;
}

.content-with-image-block .image-block .caption {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 1rem 1.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.content-with-image-block .title {
  margin-bottom: 55px;
}

.content-with-image-block .content-block {
  padding: 100px 6rem;
}

.content-with-image-block .content-block .title {
  color: #151515;
}

.content-with-image-block .content-block h6 {
  width: 90%;
  margin-bottom: 20px;
}

.content-with-image-block .content-block p:last-of-type {
  margin-bottom: 40px;
}

.content-with-image-block .btn {
  margin-left: -15px;
}

.branded-image-with-content {
  padding: 130px 0;
  color: white;
}

.branded-image-with-content .title {
  margin-bottom: 50px;
}

.branded-image-with-content h6 {
  margin-bottom: 20px;
}

.branded-image-with-content p:last-of-type {
  margin-bottom: 40px;
}

.branded-image-with-content .btn {
  margin-left: -15px;
}

.our-brands-block {
  padding: 75px 0;
}

.our-brands-block .title {
  color: #13216A;
  margin-bottom: 40px;
}

.our-brands-block p:last-of-type {
  margin-bottom: 80px;
}

.our-brands-block .our-brands .our-brand {
  margin-bottom: 20px;
}

.our-brands-block .our-brands .our-brand:not(.has-background) .our-brands-wrapper {
  background-color: #13216A;
}

.our-brands-block .our-brands .our-brand .our-brands-wrapper {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  border-bottom: 10px solid #13216A;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  position: relative;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.our-brands-block .our-brands .our-brand .our-brands-wrapper:before {
  content: "";
  display: block;
  width: 100%;
}

.our-brands-block .our-brands .our-brand .our-brands-wrapper .image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.our-brands-block .our-brands .our-brand .our-brands-wrapper .image-wrapper img {
  -o-object-fit: cover;
  object-fit: cover;
  min-height: 100%;
}

.our-brands-block .our-brands .our-brand .our-brands-wrapper .content-wrapper {
  z-index: 10;
  padding: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.our-brands-block .our-brands .our-brand .our-brands-wrapper .content-wrapper .title {
  color: white;
  margin-bottom: 20px;
}

.our-brands-block .our-brands .our-brand .our-brands-wrapper .content-wrapper p {
  color: white;
}

.our-brands-block .our-brands .our-brand .our-brands-wrapper .content-wrapper p:last-of-type {
  margin-bottom: 20px;
}

.our-brands-block .our-brands .our-brand .our-brands-wrapper .content-wrapper .btn-expand .hover-arrow {
  display: none;
}

.our-brands-block .our-brands .our-brand .our-brands-wrapper .content-wrapper .text-end {
  bottom: 40px;
  left: 40px;
  right: 40px;
}

.our-brands-block .our-brands .our-brand .our-brands-wrapper .content-wrapper:hover p, .our-brands-block .our-brands .our-brand .our-brands-wrapper .content-wrapper:hover .text-end {
  opacity: 1;
}

.our-brands-block .our-brands .our-brand .our-brands-wrapper .content-wrapper:hover .btn:after {
  width: 100%;
  height: 40px;
}

.our-brands-block .our-brands .our-brand .our-brands-wrapper .content-wrapper:hover .btn-expand img {
  display: none;
}

.our-brands-block .our-brands .our-brand .our-brands-wrapper .content-wrapper:hover .btn-expand .hover-arrow {
  display: inline-block;
}

.our-brands-block .two-columns.our-brands .our-brand .our-brands-wrapper:before {
  padding-top: 65.25%;
}

.our-brands-block .three-columns.our-brands .our-brand .our-brands-wrapper:before {
  padding-top: 125%;
}

.our-brands-block.white-background .our-brands .our-brand .our-brands-wrapper:hover {
  border-bottom-color: #C4BB9E;
}

.our-brands-block.white-background .our-brands .our-brand .our-brands-wrapper:hover .title {
  color: white;
}

.our-brands-block.white-background .our-brands .our-brand .our-brands-wrapper:hover .btn {
  color: white;
}

.our-brands-block.white-background .our-brands .our-brand .our-brands-wrapper:hover .btn:after {
  background-color: #C4BB9E;
}

.our-brands-block.blue-background {
  background-color: #13216A;
  color: white;
}

.our-brands-block.blue-background .title {
  color: white;
}

.our-brands-block.blue-background .our-brands-wrapper {
  background-color: white;
  border-bottom-color: #C4BB9E;
  color: #151515;
}

.our-brands-block.blue-background .our-brands-wrapper .title {
  color: #151515;
}

.our-brands-block.blue-background .our-brands-wrapper:hover {
  color: #151515;
  background-color: #C4BB9E;
  border-bottom-color: white;
}

.our-brands-block.blue-background .our-brands-wrapper:hover .title {
  color: #151515;
}

.our-brands-block.blue-background .our-brands-wrapper:hover .btn {
  color: white;
}

.our-sites-block {
  margin-top: 80px;
  margin-bottom: 80px;
}

.our-sites-block .our-sites-content {
  padding-bottom: 40px;
}

.our-sites-block .our-sites .our-site {
  margin-bottom: 20px;
}

.our-sites-block .our-sites .our-site .our-site-wrapper {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}

.our-sites-block .our-sites .our-site .our-site-wrapper h5 {
  min-height: 50px;
}

.our-sites-block .our-sites .our-site .our-site-wrapper h5 a {
  color: #151515;
}

.our-sites-block .our-sites .our-site .our-site-wrapper .image-wrapper img {
  height: 220px;
  object-fit: cover;
  object-position: center center;
}

.our-sites-block .our-sites .our-site .our-site-wrapper .content-wrapper {
  padding: 20px;
}

.our-sites-block .our-sites .our-site .our-site-wrapper .content-wrapper *:last-child {
  margin-bottom: 0;
}

.core-values-block {
  padding: 75px 0 50px;
}

.core-values-block .title {
  margin-bottom: 40px;
}

.core-values-block p {
  margin-bottom: 80px;
}

.core-values-block .core-values-wrapper {
  padding: 40px;
  margin-bottom: 20px;
  color: #151515;
  background-color: white;
  border-bottom: 10px solid #13216A;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.15);
}

.core-values-block .core-values-wrapper .core-values-img {
  margin-bottom: 60px;
}

.core-values-block .core-values-wrapper .core-values-img img {
  height: 60px;
  width: auto;
}

.core-values-block .core-values-wrapper .core-values-content .title {
  margin-bottom: 20px;
}

.core-values-block .core-values-wrapper .core-values-content p {
  margin-bottom: 15px;
}

.core-values-block.blue-background {
  background-color: #13216A;
  color: white;
}

.core-values-block.blue-background .core-values-wrapper {
  border-color: #C4BB9E;
}

.core-values-block.image-background {
  background-size: cover;
  background-position: top right;
  color: #C4BB9E;
  position: relative;
}

.core-values-block.image-background:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.core-values-block.image-background .container {
  position: relative;
  z-index: 1;
}

.core-values-block.image-background p {
  color: white;
}

.core-values-block.image-background .core-values-wrapper {
  border-color: #C4BB9E;
}

.core-values-block.image-background .core-values-wrapper p {
  color: #151515;
}

.content-logo-image-block .content-block .logo-icon {
  width: auto;
  max-width: 100%;
  margin-bottom: 30px;
}

.content-logo-image-block .content-block .title {
  color: #13216A;
  margin-bottom: 15px;
}

.content-logo-image-block .content-block .social-links {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin-bottom: 40px;
}

.content-logo-image-block .content-block .social-links li:not(:last-child) {
  margin-right: 10px;
}

.content-logo-image-block .content-block .social-links li a {
  width: 24px;
  height: 24px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 100%;
  background-color: #0C174F;
  padding: 5px;
}

.content-logo-image-block .content-block .social-links li a img {
  width: 10px;
  height: auto;
}

.content-logo-image-block .content-block .full-title {
  background-color: #0C174F;
  padding: 24px 20px;
}

.content-logo-image-block .content-block .full-title h6 {
  color: white;
}

.content-logo-image-block .content-block .full-title .btn {
  color: white;
}

.content-logo-image-block .content-block .full-title .btn:after {
  background-color: white;
}

.content-logo-image-block .content-block .full-title .btn:hover {
  color: #0C174F;
}

.content-logo-image-block .image-block .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.content-logo-image-block .image-block .overlay:not(.image-overlay) {
  background-color: rgba(0, 0, 0, 0.6);
}

.content-logo-image-block .image-block .overlay.image-overlay {
  opacity: 0.25;
}

.content-logo-image-block .image-block .overlay.bottom-right-corner {
  background-size: 200px;
  background-position: bottom 40px right 40px;
  background-repeat: no-repeat;
}

.content-logo-image-block .image-block .overlay.cover-image {
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center center;
}

.content-logo-image-block .image-block:before, .content-logo-image-block .image-block:after {
  display: none;
}

.content-logo-image-block .row.flex-sm-row-reverse .content-block {
  padding-right: 6rem !important;
}

.content-logo-image-block .row:not(.flex-sm-row-reverse) .content-block {
  padding-left: 6rem !important;
}

.content-block {
  padding: 75px 0;
}

.content-block .logo-icon {
  width: auto;
  margin-bottom: 50px;
}

.content-block .title {
  color: #13216A;
  margin-bottom: 50px;
}

.content-block .btn {
  margin-left: -15px;
}

.gallery-block {
  padding: 75px 0;
}

.gallery-block .title {
  color: #13216A;
  margin-bottom: 40px;
}

.gallery-block .swiper-slide img {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}

.gallery-block .swiper-slide [class^="col-"] {
  margin-bottom: 20px;
  overflow: hidden;
}

.gallery-block .swiper-button-next, .gallery-block .swiper-button-prev {
  width: 40px;
  height: 40px;
  background-size: 100%;
  background-position: center;
  top: 50%;
  background-repeat: no-repeat;
  z-index: 1;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.gallery-block .swiper-button-next:after, .gallery-block .swiper-button-prev:after {
  content: '';
  position: absolute;
  top: 50%;
  right: -20px;
  width: 40px;
  height: 40px;
  background-color: #13216A;
  z-index: -2;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.gallery-block .swiper-button-next:before, .gallery-block .swiper-button-prev:before {
  content: '';
  width: 40px;
  height: 40px;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
  background-image: url("../../../inc/assets/long-arrow-right-creame.svg");
}

.gallery-block .swiper-button-next:hover:after, .gallery-block .swiper-button-prev:hover:after {
  width: 65px;
  height: 65px;
}

.gallery-block .swiper-button-next {
  right: -90px;
}

.gallery-block .swiper-button-prev {
  left: -90px;
}

.gallery-block .swiper-button-prev:before {
  background-image: url("../../../inc/assets/long-arrow-left-creame.svg");
}

.gallery-block .swiper-button-prev:after {
  left: -20px;
  right: auto;
}

.gallery-block .swiper-container {
  padding-bottom: 50px;
}

.gallery-block .swiper-pagination {
  width: auto;
}

.gallery-block .swiper-pagination .swiper-pagination-bullet {
  width: 20px;
  height: 6px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  background-color: #C4BB9E;
  opacity: 1;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.gallery-block .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 50px;
  background-color: #13216A;
}

#cutting-tech-gallery-block {
  padding-bottom: 0;
}

.testimonials-block {
  position: relative;
  padding: 97px 0 207px;
  overflow: hidden;
}

.testimonials-block .overlay {
  display: none;
}

.testimonials-block img {
  width: 80px;
  opacity: 0.5;
  margin-bottom: 45px;
}

.testimonials-block .title {
  margin-bottom: 35px;
}

.testimonials-block p:last-of-type {
  margin-bottom: 0;
}

.testimonials-block.blue-background {
  background-color: #13216A;
  color: white;
}

.testimonials-block.blue-background .title {
  color: #C4BB9E;
}

.testimonials-block.white-background {
  background-color: white;
  color: #151515;
}

.testimonials-block.white-background .title {
  color: #151515;
}

.testimonials-block.image-background {
  color: white;
  background-size: cover;
  background-position: center;
}

.testimonials-block.image-background .title {
  color: #C4BB9E;
}

.testimonials-block.image-background .overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.testimonials-block.image-background .container {
  position: relative;
  z-index: 1;
}

.image-form-block {
  padding: 0;
}

.image-form-block .image-block {
  background-size: cover;
  background-position: center;
  -webkit-border-radius: 0 10px 10px 0;
  -moz-border-radius: 0 10px 10px 0;
  -ms-border-radius: 0 10px 10px 0;
  border-radius: 0 10px 10px 0;
  overflow: hidden;
}

.image-form-block .title {
  color: #13216A;
  margin-bottom: 20px;
}

.image-form-block .form-block {
  padding-top: 100px;
  padding-bottom: 100px;
}

.image-form-block .wpcf7-response-output {
  color: #151515;
}

.partners-logos-block {
  padding: 75px 0;
}

.partners-logos-block .title {
  color: #13216A;
  margin-bottom: 80px;
}

.partners-logos-block .swiper-container .swiper-slide {
  padding-left: 1rem;
  padding-right: 1rem;
}

.partners-logos-block img {
  width: 100%;
}

.image-testimonials-block .image-block {
  background-size: cover;
  background-position: top center;
  overflow: hidden;
  position: relative;
  padding-top: 40%;
  background-repeat: no-repeat;
}

.image-testimonials-block .title {
  margin-bottom: 60px;
  color: #13216A;
}

.image-testimonials-block .testimonial-content-block {
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
}

.image-testimonials-block .testimonial-content-block .quote-icon {
  width: 57px;
  margin-bottom: 40px;
  opacity: 0.5;
}

.image-testimonials-block .testimonial-content-block .quote {
  margin-bottom: 40px;
  color: #13216A;
}

.image-testimonials-block .testimonial-content-block .author-name {
  color: #13216A;
}

.image-testimonials-block .testimonial-content-block .job-title {
  color: #151515;
}

.image-testimonials-block .testimonial-content-block .btn {
  margin-top: 45px;
  display: inline-block;
}

.image-testimonials-block.blue-background {
  background-color: #13216A;
  color: white;
}

.image-testimonials-block.blue-background .testimonial-content-block .quote, .image-testimonials-block.blue-background .testimonial-content-block .author-name {
  color: #C4BB9E;
}

.image-testimonials-block.blue-background .testimonial-content-block .job-title {
  color: white;
}

.image-testimonials-block.white-background {
  background-color: white;
  color: #151515;
}

.image-testimonials-block.white-background .title {
  color: #151515;
}

.image-testimonials-block .row.flex-sm-row-reverse .testimonial-content-block {
  padding-right: 6rem !important;
}

.image-testimonials-block .row:not(.flex-sm-row-reverse) .testimonial-content-block {
  padding-left: 6rem !important;
}

.team-block {
  padding: 75px 0;
}

.team-block .title {
  color: #13216A;
  margin-bottom: 40px;
}

.team-block .sub-title {
  margin-bottom: 40px;
  color: #13216A;
}

.team-block .team-wrapper .team-img {
  position: relative;
  margin-bottom: 40px;
  overflow: hidden;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}

.team-block .team-wrapper .team-img img {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.team-block .team-wrapper .team-img img.hover-image {
  opacity: 0;
}

.team-block .team-wrapper .title {
  color: #151515;
  margin-bottom: 10px;
}

.team-block .team-wrapper .title a {
  color: #151515;
}

.team-block .team-wrapper p {
  font-style: italic;
}

.team-block .team-next-nav, .team-block .team-previous-nav {
  width: 40px;
  height: 40px;
  background-size: 100%;
  background-position: center;
  top: calc(50% - 80px);
  background-repeat: no-repeat;
  z-index: 1;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.team-block .team-next-nav:focus, .team-block .team-previous-nav:focus {
  outline: none;
}

.team-block .team-next-nav:after, .team-block .team-previous-nav:after {
  content: '';
  position: absolute;
  top: 50%;
  right: -20px;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-color: #13216A;
  z-index: -2;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.team-block .team-next-nav:before, .team-block .team-previous-nav:before {
  content: '';
  width: 40px;
  height: 40px;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
  background-image: url("../../../inc/assets/long-arrow-right-creame.svg");
}

.team-block .team-next-nav:hover:after, .team-block .team-previous-nav:hover:after {
  width: 65px;
  height: 65px;
}

.team-block .team-next-nav {
  right: -90px;
}

.team-block .team-previous-nav {
  left: -90px;
}

.team-block .team-previous-nav:before {
  background-image: url("../../../inc/assets/long-arrow-left-creame.svg");
}

.team-block .team-previous-nav:after {
  left: -20px;
  right: auto;
}

.team-block .swiper-container {
  padding-bottom: 50px;
}

.team-block .swiper-pagination {
  width: auto;
}

.team-block .swiper-pagination .swiper-pagination-bullet {
  width: 20px;
  height: 6px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  background-color: #C4BB9E;
  opacity: 1;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.team-block .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 50px;
  background-color: #13216A;
}

.news-block {
  padding: 75px 0;
}

.news-block .title {
  color: #13216A;
  margin-bottom: 40px;
}

.news-block .swiper-slide {
  padding: 0 15px;
  height: 100%;
}

.news-block .news-next-nav, .news-block .news-previous-nav {
  width: 40px;
  height: 40px;
  background-size: 100%;
  background-position: center;
  top: calc(50% - 80px);
  background-repeat: no-repeat;
  z-index: 1;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.news-block .news-next-nav:focus, .news-block .news-previous-nav:focus {
  outline: none;
}

.news-block .news-next-nav:after, .news-block .news-previous-nav:after {
  content: '';
  position: absolute;
  top: 50%;
  right: -20px;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-color: #13216A;
  z-index: -2;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.news-block .news-next-nav:before, .news-block .news-previous-nav:before {
  content: '';
  width: 40px;
  height: 40px;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
  background-image: url("../../../inc/assets/long-arrow-right-creame.svg");
}

.news-block .news-next-nav:hover:after, .news-block .news-previous-nav:hover:after {
  width: 65px;
  height: 65px;
}

.news-block .news-next-nav {
  right: -90px;
}

.news-block .news-previous-nav {
  left: -90px;
}

.news-block .news-previous-nav:before {
  background-image: url("../../../inc/assets/long-arrow-left-creame.svg");
}

.news-block .news-previous-nav:after {
  left: -20px;
  right: auto;
}

.news-block .news-pagination {
  width: auto;
}

.news-block .news-pagination .swiper-pagination-bullet {
  width: 20px;
  height: 6px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  background-color: #C4BB9E;
  opacity: 1;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.news-block .news-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 50px;
  background-color: #13216A;
}

.news-block .swiper-container {
  padding-bottom: 80px;
}

.news-block .view-all-btn {
  position: absolute;
  bottom: 15px;
  right: 0;
}

.news-wrapper {
  background-color: white;
  padding: 40px 40px 80px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  height: 100%;
  position: relative;
}

.news-wrapper:not(.news-recent-wrapper) .image-wrapper {
  height: 170px;
}

.news-wrapper:not(.news-recent-wrapper) .image-wrapper a {
  height: 100%;
}

.news-wrapper:not(.news-recent-wrapper) .image-wrapper img {
  object-fit: cover;
  height: 100%;
}

.news-wrapper .image-wrapper {
  margin-bottom: 45px;
}

.news-wrapper .image-wrapper a {
  display: block;
}

.news-wrapper .news-image {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
}

.news-wrapper .date {
  display: inline-block;
  margin-bottom: 20px;
}

.news-wrapper .news-title {
  margin-bottom: 20px;
}

.news-wrapper .news-title a {
  color: #13216A;
}

.news-wrapper p:last-of-type {
  margin-bottom: 40px;
}

.news-wrapper .text-end {
  position: absolute;
  bottom: 40px;
  left: 40px;
  right: 40px;
}

.news-wrapper.news-recent-wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
}

.news-wrapper.news-recent-wrapper .image-wrapper {
  width: 55.4%;
  margin: 0;
}

.news-wrapper.news-recent-wrapper .content-wrapper {
  width: 44.6%;
  padding: 0 40px;
}

.news-wrapper.news-recent-wrapper .content-wrapper .title {
  color: #13216A;
  margin-bottom: 17px;
}

.news-wrapper.news-recent-wrapper .content-wrapper .date {
  margin-bottom: 5px;
}

.news-list-section {
  padding-top: 75px;
  padding-bottom: 75px;
}

.news-list-section .title-main {
  color: #13216A;
  margin-bottom: 0;
}

.news-list-section .items {
  margin-top: 40px;
}

.news-list-section .items .item {
  margin-top: 40px;
}

.post-content {
  padding-top: 225px;
}

.post-content #crumbs a {
  color: #13216A;
}

.post-content #crumbs a:last-of-type + .arrow:after {
  background-color: #13216A;
}

.post-content #crumbs .arrow {
  background-image: url("../../../inc/assets/long-arrow-right-blue.svg");
}

.post-content #crumbs .current {
  color: #13216A;
}

.post-content .auther-name {
  font-style: italic;
  color: #13216A;
  display: block;
  margin: 8px 0 40px;
}

.post-content .title-main {
  color: #13216A;
  width: 60%;
  margin-bottom: 35px;
}

.post-content .full-img {
  margin-bottom: 85px;
}

.post-content img {
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  border-radius: 10px !important;
}

.post-content .title {
  color: #13216A;
  margin-bottom: 40px;
}

.post-content .wp-block-columns {
  margin-bottom: 30px;
}

.post-content .wp-block-columns .wp-block-column p:last-of-type {
  margin-bottom: 0;
}

.similar-posts {
  padding: 75px 0;
}

.similar-posts .title {
  color: #13216A;
}

.careers-listing {
  background-color: #C4BB9E;
  position: relative;
  overflow: hidden;
  padding: 200px 0;
}

.careers-listing:after, .careers-listing:before {
  content: '';
  width: 2px;
  height: 200px;
  background-color: white;
  position: absolute;
  -moz-transform: rotate(30deg);
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
  z-index: 0;
}

.careers-listing:after {
  bottom: -20px;
  left: 400px;
}

.careers-listing:before {
  top: -20px;
  right: 410px;
}

.careers-listing .overlay {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../inc/assets/circle-patterns.svg");
  background-position: center;
  background-position: center left -100px;
  background-repeat: no-repeat;
  opacity: 0.2;
  z-index: 0;
}

.careers-listing .title-main {
  color: #13216A;
  margin-bottom: 40px;
}

.careers-listing p {
  margin-bottom: 40px;
}

.careers-listing .careers-list {
  padding: 0;
  margin: 0;
}

.careers-listing .careers-list li {
  padding: 29px 40px 26px;
  background-color: white;
  color: #13216A;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}

.careers-listing .careers-list li:nth-child(even) {
  background-color: #13216A;
  color: white;
}

.careers-listing .careers-list li:nth-child(even) .btn {
  color: white;
}

.careers-listing .careers-list li:nth-child(even) .btn:after {
  background-color: white;
}

.careers-listing .careers-list li:nth-child(even) .btn:hover {
  color: #13216A;
}

.careers-listing .careers-list li .title {
  margin: 0;
  line-height: 24px;
}

.single-careers #crumbs {
  margin-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.single-careers #crumbs a {
  color: #13216A;
}

.single-careers #crumbs a:last-of-type + .arrow {
  background-image: url("../../../inc/assets/long-arrow-right-creame.svg");
}

.single-careers #crumbs a:last-of-type + .arrow:after {
  background-color: #13216A;
}

.single-careers #crumbs .arrow {
  background-image: url("../../../inc/assets/long-arrow-right-blue.svg");
}

.single-careers #crumbs .current {
  color: #13216A;
}

.career-single-post {
  padding: 200px 0 80px;
}

.career-single-post .title-main {
  color: #13216A;
  margin: 40px 0 25px;
}

.career-single-post .single-post-left {
  padding-right: 17px;
}

.career-single-post .single-post-left .wp-block-table {
  background: rgba(196, 187, 158, 0.1);
  padding: 19px 30px 16px;
  margin-bottom: 40px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}

.career-single-post .single-post-left .wp-block-table table tr {
  border-bottom: 1px solid rgba(19, 33, 106, 0.1);
}

.career-single-post .single-post-left .wp-block-table table tr:last-child {
  border: none;
}

.career-single-post .single-post-left .wp-block-table table tr:last-child td {
  padding-bottom: 0;
}

.career-single-post .single-post-left .wp-block-table table tr:first-child td {
  padding-top: 0;
}

.career-single-post .single-post-left .wp-block-table table tr td {
  padding: 12px 0 13px;
}

.career-single-post .single-post-left .wp-block-table table tr td strong {
  font-size: 18px;
  padding-right: 10px;
  color: #13216A;
}

.career-single-post .single-post-left .wp-block-table table tr td:last-child {
  width: 150px;
}

.career-single-post .single-post-left .wp-block-table table tr td:nth-child(2) {
  width: 206px;
}

.career-single-post .single-post-left .title {
  margin: 40px 0 40px;
}

.career-single-post .single-post-left .list-style {
  list-style: none;
  padding: 0;
  margin: 0;
}

.career-single-post .single-post-left .list-style li {
  padding-left: 35px;
  margin-bottom: 10px;
  position: relative;
}

.career-single-post .single-post-left .list-style li:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 0;
  width: 12px;
  height: 12px;
  background-color: #13216A;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.career-single-post .single-post-left .list-style li:last-child {
  margin-bottom: 0;
}

.career-single-post .single-post-left .btn {
  margin-top: 40px;
}

.career-single-post .single-post-right {
  padding-left: 43px;
}

.career-single-post .single-post-right .wpcf7 {
  position: relative;
  padding: 40px 32px;
  background-color: #13216A;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}

.career-single-post .single-post-right .wpcf7:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../inc/assets/circle-patterns.svg");
  background-size: cover;
  background-position: left -210px top -5px;
  background-repeat: no-repeat;
  opacity: 0.1;
  z-index: 0;
}

.career-single-post .single-post-right .wpcf7 form {
  position: relative;
  overflow: hidden;
}

.career-single-post .single-post-right .wpcf7 form .title {
  color: white;
  margin-bottom: 30px;
}

.career-single-post .single-post-right .wpcf7 form label {
  color: white;
}

.career-single-post .single-post-right .wpcf7 form input {
  background-color: white;
}

.career-single-post .single-post-right .wpcf7 form input[type=submit] {
  background-color: #C4BB9E;
}

.career-single-post .single-post-right .wpcf7 form input[type=checkbox] {
  width: 20px;
  height: 20px;
}

.career-single-post .single-post-right .wpcf7 form .cv-wrapper {
  display: inline-block;
  position: relative;
  width: auto;
  cursor: pointer;
  margin-bottom: 25px;
}

.career-single-post .single-post-right .wpcf7 form .cv-wrapper .wpcf7-form-control-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: 0 0;
  color: #fff;
  padding: 15px 35px;
  display: inline-block;
  cursor: pointer;
}

.career-single-post .single-post-right .wpcf7 form .cv-wrapper input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.career-single-post .single-post-right .wpcf7 form .cv-wrapper span {
  position: relative;
  z-index: 1;
  color: white;
  padding: 15px 35px;
  display: inline-block;
  cursor: pointer;
  background: #030304;
  cursor: pointer;
  -webkit-border-radius: 28px;
  -moz-border-radius: 28px;
  -ms-border-radius: 28px;
  border-radius: 28px;
}

.career-single-post .single-post-right .wpcf7 form .cv-wrapper .wpcf7-not-valid-tip {
  width: 230%;
  padding: 0;
  position: absolute;
  left: 10px;
  bottom: -25px;
  color: red;
  background-color: transparent;
  font-size: 13px;
}

.career-single-post .single-post-right .wpcf7 form .cv-wrapper .wpcf7-response-output {
  font-size: 13px;
}

.career-single-post .single-post-right .wpcf7 form .wpcf7-checkbox .wpcf7-list-item {
  margin-top: 20px;
}

.career-single-post .single-post-right .wpcf7 form .cv-input-wrapper p {
  display: inline-block;
  margin-left: 10px;
  color: white;
}

.contact-page #crumbs {
  z-index: 1;
}

.contact-page #crumbs .arrow:after {
  background-color: white;
}

.contact-page #crumbs .current {
  color: white;
}

.contact-form {
  padding: 75px 0;
  background-color: #13216A;
}

.contact-form .title {
  color: white;
  margin-bottom: 20px;
}

.contact-form .contact-content {
  color: white;
  margin-bottom: 72px;
  padding-right: 4rem;
}

.contact-form .contact-content img {
  width: auto;
  height: 40px;
  margin-bottom: 40px;
}

.contact-form .contact-content .title {
  color: white;
  margin-bottom: 10px;
}

.contact-form .contact-content p {
  margin-bottom: 5px;
}

.contact-form .contact-content a {
  color: white;
}

.contact-form .wpcf7-form {
  margin-bottom: 50px;
}

.contact-form .wpcf7-form label {
  color: white;
}

.contact-form .wpcf7-form input, .contact-form .wpcf7-form select, .contact-form .wpcf7-form textarea {
  background-color: white;
}

.contact-form .wpcf7-form input[type="submit"] {
  background-color: #C4BB9E;
}

.contact-form iframe {
  margin-top: 100px;
}

.paging-navigation .nav-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 80px;
}

.paging-navigation .nav-links .nav-previous .btn-expand {
  padding-right: 85px;
}

.paging-navigation .nav-links .nav-previous .btn-expand:before {
  content: "Previous";
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: 2px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.paging-navigation .nav-links .nav-previous .btn-expand:after {
  left: 0;
  right: inherit;
}

.paging-navigation .nav-links .nav-previous .btn-expand img {
  margin: 0 17px 0 0;
}

.paging-navigation .nav-links .nav-next .btn-expand {
  padding-left: 55px;
}

.paging-navigation .nav-links .nav-next .btn-expand:before {
  content: "Next";
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: 2px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.home .hero-banner-block-homepage + .content-with-image-block .image-block {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}

.about-us-page .team-block + .team-block {
  padding-top: 0;
}

.investment-opportunities-page .testimonials-block {
  margin: 100px 0;
}

.news-page .news-wrapper {
  margin-bottom: 40px;
}

.not-found {
  padding-top: 125px;
}

.not-found .not-found-content {
  height: 600px;
}

.not-found .not-found-content h1 {
  font-size: 80px;
  margin-bottom: 30px;
}

.not-found .not-found-content span {
  display: block;
  margin-bottom: 25px;
}

.not-found .not-found-content .btn-expand:after {
  left: 0;
  right: inherit;
}

.not-found .not-found-content .btn-expand img {
  margin: 0 17px 0 0;
}

.similar-posts .title {
  margin-bottom: 40px;
}

.search-block {
  padding: 200px 0 100px;
}

.search-block .serach-title {
  margin-bottom: 30px;
}

.meet-the-team-list-section {
  padding: 75px 0;
}

.meet-the-team-list-section .title-main {
  color: #13216A;
  margin-bottom: 0;
}

.meet-the-team-list-section .team-members {
  margin-top: 20px;
  margin-bottom: 20px;
}

.meet-the-team-list-section .team-member-wrapper {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  height: 400px;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

.meet-the-team-list-section .team-member-wrapper a {
  color: black;
  height: 100%;
}

.meet-the-team-list-section .team-member-wrapper a .image-wrapper {
  height: 100%;
}

.meet-the-team-list-section .team-member-wrapper a .image-wrapper .casual-photo {
  opacity: 1;
}

.meet-the-team-list-section .team-member-wrapper a .image-wrapper .professional-photo {
  opacity: 0;
}

.meet-the-team-list-section .team-member-wrapper a .image-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: opacity 0.5s ease-in-out;
  object-fit: cover;
}

.meet-the-team-list-section .team-member-wrapper a .image-wrapper:before {
  transition: opacity 0.5s ease-in-out;
  content: "";
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0;
}

.meet-the-team-list-section .team-member-wrapper a .content-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 2rem;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.meet-the-team-list-section .team-member-wrapper a .content-wrapper .team-member-title small {
  display: block;
  font-weight: normal;
}

.meet-the-team-list-section .team-member-wrapper a:hover .image-wrapper .casual-photo {
  opacity: 1;
}

.meet-the-team-list-section .team-member-wrapper a:hover .image-wrapper .professional-photo {
  opacity: 0;
}

.meet-the-team-list-section .team-member-wrapper a:hover .image-wrapper:before {
  opacity: 1;
}

.meet-the-team-list-section .team-member-wrapper a:hover .content-wrapper {
  opacity: 1;
}

.teammembers-single-post {
  padding: 200px 0 80px;
}

.teammembers-single-post #crumbs {
  margin-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.teammembers-single-post #crumbs a {
  color: #13216A;
}

.teammembers-single-post #crumbs a:last-of-type + .arrow {
  background-image: url("../../../inc/assets/long-arrow-right-creame.svg");
}

.teammembers-single-post #crumbs a:last-of-type + .arrow:after {
  background-color: #13216A;
}

.teammembers-single-post #crumbs .arrow {
  background-image: url("../../../inc/assets/long-arrow-right-blue.svg");
}

.teammembers-single-post #crumbs .current {
  color: #13216A;
}

.teammembers-single-post .title-main {
  color: #13216A;
  margin: 40px 0 25px;
}

.teammembers-single-post .single-post-left {
  padding-right: 17px;
}

.teammembers-single-post .single-post-left .contact-details {
  list-style: none;
  padding-left: 0;
  margin-top: 10px;
  margin-bottom: 20px;
}

.teammembers-single-post .single-post-left .social-links {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin-top: 10px;
  margin-bottom: 20px;
}

.teammembers-single-post .single-post-left .social-links li:not(:last-child) {
  margin-right: 10px;
}

.teammembers-single-post .single-post-left .social-links li a {
  width: 24px;
  height: 24px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 100%;
  background-color: #0C174F;
  padding: 5px;
}

.teammembers-single-post .single-post-left .social-links li a img {
  width: 10px;
  height: auto;
}

.teammembers-single-post .single-post-left .wp-block-table {
  background: rgba(196, 187, 158, 0.1);
  padding: 19px 30px 16px;
  margin-bottom: 40px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}

.teammembers-single-post .single-post-left .wp-block-table table tr {
  border-bottom: 1px solid rgba(19, 33, 106, 0.1);
}

.teammembers-single-post .single-post-left .wp-block-table table tr:last-child {
  border: none;
}

.teammembers-single-post .single-post-left .wp-block-table table tr:last-child td {
  padding-bottom: 0;
}

.teammembers-single-post .single-post-left .wp-block-table table tr:first-child td {
  padding-top: 0;
}

.teammembers-single-post .single-post-left .wp-block-table table tr td {
  padding: 12px 0 13px;
}

.teammembers-single-post .single-post-left .wp-block-table table tr td strong {
  font-size: 18px;
  padding-right: 10px;
  color: #13216A;
}

.teammembers-single-post .single-post-left .wp-block-table table tr td:last-child {
  width: 150px;
}

.teammembers-single-post .single-post-left .wp-block-table table tr td:nth-child(2) {
  width: 206px;
}

.teammembers-single-post .single-post-left .title {
  margin: 40px 0 40px;
}

.teammembers-single-post .single-post-left .list-style {
  list-style: none;
  padding: 0;
  margin: 0;
}

.teammembers-single-post .single-post-left .list-style li {
  padding-left: 35px;
  margin-bottom: 10px;
  position: relative;
}

.teammembers-single-post .single-post-left .list-style li:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 0;
  width: 12px;
  height: 12px;
  background-color: #13216A;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.teammembers-single-post .single-post-left .list-style li:last-child {
  margin-bottom: 0;
}

.teammembers-single-post .single-post-left .btn {
  margin-top: 40px;
}

.teammembers-single-post .single-post-right {
  padding-left: 43px;
}

.teammembers-single-post .single-post-right .wpcf7 {
  position: relative;
  padding: 40px 32px;
  background-color: #13216A;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}

.teammembers-single-post .single-post-right .wpcf7:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../inc/assets/circle-patterns.svg");
  background-size: cover;
  background-position: left -210px top -5px;
  background-repeat: no-repeat;
  opacity: 0.1;
  z-index: 0;
}

.teammembers-single-post .single-post-right .wpcf7 form {
  position: relative;
  overflow: hidden;
}

.teammembers-single-post .single-post-right .wpcf7 form .title {
  color: white;
  margin-bottom: 30px;
}

.teammembers-single-post .single-post-right .wpcf7 form label {
  color: white;
}

.teammembers-single-post .single-post-right .wpcf7 form input {
  background-color: white;
}

.teammembers-single-post .single-post-right .wpcf7 form input[type=submit] {
  background-color: #C4BB9E;
}

.teammembers-single-post .single-post-right .wpcf7 form input[type=checkbox] {
  width: 20px;
  height: 20px;
}

.teammembers-single-post .single-post-right .wpcf7 form .cv-wrapper {
  display: inline-block;
  position: relative;
  width: auto;
  cursor: pointer;
  margin-bottom: 25px;
}

.teammembers-single-post .single-post-right .wpcf7 form .cv-wrapper .wpcf7-form-control-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: 0 0;
  color: #fff;
  padding: 15px 35px;
  display: inline-block;
  cursor: pointer;
}

.teammembers-single-post .single-post-right .wpcf7 form .cv-wrapper input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.teammembers-single-post .single-post-right .wpcf7 form .cv-wrapper span {
  position: relative;
  z-index: 1;
  color: white;
  padding: 15px 35px;
  display: inline-block;
  cursor: pointer;
  background: #030304;
  cursor: pointer;
  -webkit-border-radius: 28px;
  -moz-border-radius: 28px;
  -ms-border-radius: 28px;
  border-radius: 28px;
}

.teammembers-single-post .single-post-right .wpcf7 form .cv-wrapper .wpcf7-not-valid-tip {
  width: 230%;
  padding: 0;
  position: absolute;
  left: 10px;
  bottom: -25px;
  color: red;
  background-color: transparent;
  font-size: 13px;
}

.teammembers-single-post .single-post-right .wpcf7 form .cv-wrapper .wpcf7-response-output {
  font-size: 13px;
}

.teammembers-single-post .single-post-right .wpcf7 form .wpcf7-checkbox .wpcf7-list-item {
  margin-top: 20px;
}

.teammembers-single-post .single-post-right .wpcf7 form .cv-input-wrapper p {
  display: inline-block;
  margin-left: 10px;
  color: white;
}

.gallery-block .button-group {
  display: inline-block;
}

.gallery-block .orbit .orbit-wrapper .orbit-controls .orbit-previous, .gallery-block .orbit .orbit-wrapper .orbit-controls .orbit-next {
  background-color: #13216A;
  width: 60px;
  height: 60px;
  border-radius: 4px;
}

.gallery-block .orbit .orbit-wrapper .orbit-controls .orbit-previous:hover, .gallery-block .orbit .orbit-wrapper .orbit-controls .orbit-next:hover {
  background-color: #151515;
  cursor: pointer;
}

.gallery-block .orbit .orbit-wrapper .orbit-controls .orbit-previous svg, .gallery-block .orbit .orbit-wrapper .orbit-controls .orbit-next svg {
  width: 27px;
  margin-top: -2px;
}

.gallery-block .orbit .orbit-wrapper .orbit-controls .orbit-previous {
  left: -30px;
}

.gallery-block .orbit .orbit-wrapper .orbit-controls .orbit-next {
  right: -30px;
}

.gallery-block .gallery-slider {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.gallery-block .gallery-slider .slide > .row {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.gallery-block .gallery-slider .inner-slide-content {
  position: relative;
  height: 100vh;
  min-height: 250px;
  max-height: 600px;
}

.gallery-block .gallery-slider .inner-slide-content .row {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.gallery-block .gallery-slider .inner-slide-content .col {
  padding-left: 7.5px;
  padding-right: 7.5px;
  background-clip: content-box;
}

.gallery-block .gallery-slider .inner-slide-content .background {
  height: 100%;
}

.gallery-block .gallery-slider .inner-slide-content .background a {
  border-radius: 4px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
  display: block;
  outline: none;
}

.gallery-block .gallery-slider .inner-slide-content .background-image-column {
  height: 100%;
}

.gallery-block .gallery-slider .inner-slide-content .background-image-column .row {
  height: 100%;
}

.gallery-block .gallery-slider .inner-slide-content .background-image-column .background.background-50h {
  height: calc(50% - (7.5px));
}

.gallery-block .gallery-slider .inner-slide-content .background-image-column .background.background-50h:first-of-type {
  margin-bottom: calc(7.5px * 2);
}

.gallery-block .gallery-slider .slick-track {
  margin: 0;
}

.archive .news-list-section {
  padding-top: 200px;
}

.archive .news-list-section .items {
  margin-top: 0;
}

footer {
  padding: 100px 0;
  background-color: #13216A;
  position: relative;
  color: white;
}

footer:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../inc/assets/circle-patterns.svg");
  background-position: center;
  background-position: center left -100px;
  background-repeat: no-repeat;
  opacity: 0.1;
}

footer a {
  color: white;
}

footer .title {
  margin-bottom: 60px;
}

footer .widget.text-2 {
  margin-bottom: 60px;
}

footer .textwidget {
  margin-bottom: 0;
}

footer .textwidget h6 {
  margin-bottom: 20px;
}

footer .social-link-title .title {
  margin-bottom: 20px;
}

footer .social-link-title .footer-social li {
  margin-right: 20px;
}

footer .social-link-title .footer-social li:last-of-type {
  margin-right: 0;
}

footer .address-col .title {
  margin-bottom: 40px;
}

footer .address-wrapper {
  margin-bottom: 60px;
}

footer .address-wrapper .title {
  margin-bottom: 20px;
}

footer .address-wrapper .content {
  position: relative;
}

footer .address-wrapper .content p {
  width: 80%;
}

footer .address-wrapper .content p:last-of-type {
  margin-bottom: 20px;
}

footer .address-wrapper img.address-icon {
  position: absolute;
  top: 3px;
  left: -38px;
  width: 20px;
}

footer .address-wrapper .social-links {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

footer .address-wrapper .social-links li:not(:last-child) {
  margin-right: 10px;
}

footer .address-wrapper .social-links li a {
  width: 24px;
  height: 24px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 100%;
  background-color: #0C174F;
  padding: 5px;
}

footer .address-wrapper .social-links li a img {
  width: 10px;
  height: auto;
}

/*================ Responsive ================*/
@media (max-width: 1800px) {
  .gallery-block .swiper-slide img {
    object-fit: cover;
    height: 350px;
  }
}

@media (max-width: 1550px) {
  .gallery-block .swiper-button-next {
    right: 15px;
  }
  .gallery-block .swiper-button-prev {
    left: 15px;
  }
  .team-block .team-next-nav {
    right: 15px;
  }
  .team-block .team-previous-nav {
    left: 15px;
  }
  .news-block .news-next-nav {
    right: 15px;
  }
  .news-block .news-previous-nav {
    left: 15px;
  }
}

@media (max-width: 1440px) {
  header {
    font-size: 16px;
    line-height: 24px;
  }
  header .navbar {
    padding: 0 30px;
  }
  header .navbar .navigation-links .menu-item > a {
    padding: 50px 15px;
  }
  .navbar-brand img {
    width: 120px;
  }
  .hero-banner-block-homepage .row {
    height: 700px;
  }
  .hero-banner-block-homepage .title-col {
    margin-top: 50px;
  }
  .content-with-image-block .content-block {
    padding: 100px 6rem;
  }
  .branded-image-with-content {
    padding: 160px 0;
  }
}

@media (max-width: 1199px) {
  header {
    font-size: 14px;
    line-height: 20px;
  }
  header .navbar {
    padding: 0 20px;
  }
  header .navbar .navigation-links .menu-item > a {
    padding: 30px 10px;
  }
  header .navbar .btn.btn-expand img {
    margin-left: 5px;
  }
  .navbar-brand img {
    width: 95px;
  }
  .content-with-image-block .content-block {
    padding: 100px 6rem;
  }
  .branded-image-with-content {
    padding: 120px 0;
  }
  .our-brands-block .our-brands-wrapper .size-icon {
    margin-bottom: 30px;
  }
  .core-values-block {
    padding: 100px 0 70px;
  }
  .news-block .news-wrapper, .news-list-section .news-wrapper {
    padding: 20px;
  }
  .news-block .news-wrapper .news-image, .news-list-section .news-wrapper .news-image {
    margin-bottom: 20px;
  }
  .news-block .news-wrapper .date, .news-list-section .news-wrapper .date {
    margin-bottom: 10px;
  }
  .post-content {
    padding-top: 160px;
  }
  .post-content .title-main {
    width: 65%;
  }
  .home .news-block .news-wrapper {
    margin-top: 20px;
  }
  .contact-form {
    padding: 150px 0 0;
  }
  .not-found {
    padding-top: 80px;
  }
  .careers-listing {
    padding: 100px 0;
  }
  .career-single-post {
    padding-top: 170px;
  }
  .career-single-post .title-main {
    margin-top: 0;
  }
  .career-single-post .single-post-right {
    padding-left: 5px;
  }
}

@media (max-width: 991px) {
  header .navbar {
    padding: 15px;
  }
  header .navbar .navigation-links .menu-item > a {
    display: block;
    font-size: 16px;
    padding: 20px 15px;
  }
  header .navbar .navigation-links .menu-item.menu-item-has-children > a:after {
    display: none;
  }
  header .navbar .navigation-links .menu-item .mobile-menu-icon {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  header .navbar .navigation-links .menu-item.show .mobile-menu-icon span {
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  header .navbar .navigation-links .menu-item.show .sub-menu {
    opacity: 1;
    visibility: visible;
    height: auto;
  }
  header .navbar .navigation-links .menu-item:hover > a {
    background: transparent;
    color: white;
  }
  header .navbar .navigation-links .menu-item:hover .mobile-menu-icon span {
    border-color: white;
  }
  header .navbar .navigation-links .menu-item.current_page_item:hover > a {
    background: #fff;
    color: #0C174F;
  }
  header .navbar .navigation-links .menu-item.current_page_item .mobile-menu-icon span {
    border-color: #0C174F;
  }
  header .navbar .navigation-links .sub-menu {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
  }
  header.dark .navbar-collapse {
    background-color: rgba(0, 0, 0, 0.9);
  }
  header.dark .navbar-collapse .navigation-links {
    background-color: rgba(0, 0, 0, 0.9);
  }
  header.dark .navbar-collapse .navigation-links .menu-item:hover > a {
    background-color: #0C174F;
  }
  header.dark .navbar-collapse .navigation-links .menu-item:hover > a:before {
    display: none;
  }
  header.dark .navbar-collapse .navigation-links .menu-item.current_page_item .mobile-menu-icon span {
    border-color: white;
  }
  header.dark .navbar-collapse .navigation-links .menu-item.current-menu-item > a {
    background-color: #0C174F;
    color: white;
  }
  header.dark .navbar-collapse .navigation-links .menu-item.current-menu-item > a:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;
    border-radius: 4px;
    background: transparent;
  }
  header.dark .navbar-collapse .navigation-links .sub-menu li > a:before {
    left: 0;
    width: 100%;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  .navbar-toggler {
    width: 40px;
    height: 40px;
    padding: 0;
    margin-right: 15px;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
    border: none;
  }
  .navbar-toggler .navbar-toggler-line {
    width: 100%;
    height: 3px;
    background-color: white;
    border-radius: 5px;
    position: relative;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  .navbar-toggler .navbar-toggler-line:before, .navbar-toggler .navbar-toggler-line:after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: white;
    position: absolute;
    left: 0;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  .navbar-toggler .navbar-toggler-line:before {
    top: -10px;
  }
  .navbar-toggler .navbar-toggler-line:after {
    top: 10px;
  }
  .navbar-toggler:not(.collapsed) .navbar-toggler-line {
    background-color: transparent;
  }
  .navbar-toggler:not(.collapsed) .navbar-toggler-line:before {
    top: 0;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .navbar-toggler:not(.collapsed) .navbar-toggler-line:after {
    top: 0;
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .navbar-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #0C174F;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  .navbar-collapse .navigation-links {
    display: block;
    background-color: #0C174F;
    max-height: 500px;
    overflow-y: auto;
  }
  .hero-banner-block-subpage:after {
    bottom: -40px;
    left: 15%;
    height: 200px;
  }
  .content-with-image-block .image-block {
    height: 500px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
  }
  .content-with-image-block .content-block {
    padding: 50px 4rem;
  }
  .branded-image-with-content {
    padding: 70px 0;
  }
  .our-brands-block {
    padding: 70px 0;
  }
  .our-brands-block .title {
    margin-bottom: 30px;
  }
  .our-brands-block p:last-of-type {
    margin-bottom: 50px;
  }
  .our-brands-block .our-brands-wrapper {
    margin-bottom: 20px 20px 50px;
  }
  .core-values-block {
    padding: 70px 0;
  }
  .core-values-block .title {
    margin-bottom: 30px;
  }
  .core-values-block p {
    margin-bottom: 50px;
  }
  .core-values-block .core-values-wrapper .core-values-img {
    margin-bottom: 40px;
  }
  .content-logo-image-block [class$="row-reverse "] .image-block, .content-logo-image-block [class$="row-reverse"] .image-block {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
  }
  .content-block {
    padding: 70px 0;
  }
  .gallery-block {
    padding: 70px 0;
  }
  .testimonials-block:before {
    right: 80px;
  }
  .testimonials-block:after {
    left: 80px;
  }
  .image-form-block .image-block {
    height: 450px;
    border-radius: 0;
  }
  .image-form-block .form-block {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .team-block {
    padding: 70px 0;
  }
  .team-block .title, .team-block .sub-title {
    margin-bottom: 20px;
  }
  .image-testimonials-block {
    padding-top: 0;
  }
  .image-testimonials-block .image-block {
    height: 500px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
  }
  .news-block {
    padding: 70px 0;
  }
  .news-wrapper {
    margin-bottom: 20px;
    padding: 20px;
  }
  .news-wrapper.news-recent-wrapper .content-wrapper {
    padding: 0 0 0 30px;
  }
  .news-list-section {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .post-content .title-main {
    width: 80%;
  }
  footer {
    padding: 70px 0;
  }
  footer .title {
    margin-bottom: 25px;
  }
  footer .textwidget h6 {
    margin-bottom: 10px;
  }
  footer .widget.text-2 {
    margin-bottom: 30px;
  }
  footer .address-col .title {
    margin-bottom: 20px;
  }
  footer .address-wrapper .content {
    padding-left: 40px;
  }
  footer .address-wrapper .content img.address-icon {
    left: 0;
    top: 0;
  }
  footer .address-wrapper .content a {
    display: block;
  }
  .news-page .news-wrapper {
    margin-bottom: 20px;
  }
  .contact-form iframe {
    margin-top: 70px;
    height: 450px;
  }
  .paging-navigation .nav-links {
    margin-top: 30px;
  }
  .investment-opportunities-page .testimonials-block {
    margin: 0;
  }
  .careers-listing {
    padding: 70px 0;
  }
  .career-single-post .single-post-left .wp-block-table {
    padding: 19px 20px 16px;
  }
  .career-single-post .single-post-right {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .hero-banner-block-homepage .row {
    min-height: 425px;
    height: 50vh;
  }
  .hero-banner-block-homepage .row .title-col {
    margin-bottom: 0;
  }
  .hero-banner-block-homepage .row .title-col p {
    margin-bottom: 0;
  }
  .news-wrapper.news-recent-wrapper .image-wrapper {
    width: 100%;
    margin: 0 0 30px;
  }
  .news-wrapper.news-recent-wrapper .content-wrapper {
    width: 100%;
    padding: 0;
  }
  .post-content {
    padding-top: 140px;
  }
  .post-content .title-main {
    width: 100%;
  }
  .post-content .full-img {
    margin-bottom: 40px;
  }
  .post-content .title {
    margin-bottom: 30px;
  }
  .post-content .wp-block-columns {
    margin-bottom: 0;
  }
  .post-content .wp-block-columns .wp-block-column {
    margin-bottom: 20px;
  }
  .content-block {
    padding: 50px 0;
  }
  .content-block .logo-icon, .content-block .title {
    margin-bottom: 30px;
  }
  .gallery-block {
    padding: 30px 0;
  }
  .similar-posts {
    padding: 50px 0;
  }
  footer {
    padding: 50px 0;
  }
  footer .address-wrapper {
    margin-bottom: 40px;
  }
  .contact-form .contact-content {
    margin-bottom: 50px;
    padding-right: 0;
  }
  .contact-form .contact-content img {
    margin-bottom: 20px;
  }
  .careers-listing .careers-list li {
    padding: 19px 20px 16px;
  }
  .career-single-post {
    padding-top: 140px;
  }
  .gallery-block .gallery-slider .inner-slide-content {
    height: 100vh;
    min-height: 250px;
    max-height: 250px;
  }
}

@media (max-width: 575px) {
  header .mobile-contact-btn {
    display: inline-block;
    padding: 35px 15px;
    margin: 20px 0;
  }
  header .contact-btn {
    display: none;
  }
  .navbar-toggler {
    margin-right: 0;
  }
  .hero-banner-block-homepage .title-col {
    text-align: center;
  }
  .hero-banner-block-homepage .title-col h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .content-with-image-block .image-block {
    height: 400px;
  }
  .content-with-image-block .image-block:before, .content-with-image-block .image-block:after {
    height: 200px;
  }
  .content-with-image-block .image-block:before {
    right: 50px;
  }
  .content-with-image-block .image-block:after {
    left: 50px;
    bottom: -100px;
  }
  .content-with-image-block .content-block {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .content-with-image-block .content-block .title {
    margin-bottom: 20px;
  }
  .content-with-image-block .content-block p:last-of-type {
    margin-bottom: 25px;
  }
  .branded-image-with-content {
    padding: 50px 0;
  }
  .branded-image-with-content .title {
    margin-bottom: 20px;
  }
  .branded-image-with-content .btn {
    margin-left: 0;
  }
  .our-brands-block {
    padding: 30px 0;
  }
  .our-brands-block .our-brands-wrapper {
    padding: 25px 25px 55px;
  }
  .our-brands-block .title {
    margin-bottom: 20px;
  }
  .core-values-block {
    padding: 50px 0 30px;
  }
  .core-values-block .title {
    margin-bottom: 20px;
  }
  .core-values-block .core-values-wrapper {
    padding: 25px;
  }
  .core-values-block .core-values-wrapper .core-values-img {
    margin-bottom: 30px;
  }
  .content-logo-image-block .content-block .full-title {
    flex-direction: column;
    padding: 15px;
  }
  .content-logo-image-block .content-block .full-title .btn {
    margin: 10px auto 0;
    font-size: 13px;
  }
  .gallery-block .swiper-button-next {
    top: calc(50% - 30px);
    right: 12px;
  }
  .gallery-block .swiper-button-next:after {
    right: -10px;
    width: 30px;
    height: 30px;
  }
  .gallery-block .swiper-button-prev {
    top: calc(50% - 30px);
    left: 12px;
  }
  .gallery-block .swiper-button-prev:after {
    left: -10px;
    width: 30px;
    height: 30px;
  }
  .gallery-block .swiper-slide img {
    height: 260px;
  }
  .testimonials-block {
    padding: 70px 0;
  }
  .testimonials-block:before, .testimonials-block:after {
    height: 90px;
  }
  .image-form-block .image-block {
    height: 300px;
  }
  .image-form-block .form-block {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .team-block .team-previous-nav {
    left: 35px;
  }
  .team-block .team-previous-nav:after {
    left: -10px;
    width: 30px;
    height: 30px;
  }
  .team-block .team-next-nav {
    right: 35px;
  }
  .team-block .team-next-nav:after {
    right: -10px;
    width: 30px;
    height: 30px;
  }
  .image-testimonials-block .image-block:after {
    bottom: -100px;
    left: 50px;
  }
  .image-testimonials-block .image-block:before {
    top: -100px;
    right: 50px;
  }
  .news-block {
    padding: 30px 0;
  }
  .news-block .title {
    margin-bottom: 10px;
  }
  .news-block .view-all-btn {
    position: relative;
    bottom: 0;
    right: 0;
  }
  .news-block .news-pagination {
    width: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .news-block .news-previous-nav {
    left: 35px;
  }
  .news-block .news-previous-nav:after {
    left: -10px;
    width: 30px;
    height: 30px;
  }
  .news-block .news-next-nav {
    right: 35px;
  }
  .news-block .news-next-nav:after {
    right: -10px;
    width: 30px;
    height: 30px;
  }
  .contact-form {
    padding-bottom: 0;
  }
  .contact-form iframe {
    height: 350px;
  }
  .careers-listing:after {
    bottom: -100px;
    left: 50px;
  }
  .careers-listing:before {
    top: -100px;
    right: 50px;
  }
  .careers-listing .careers-list li {
    padding: 29px 20px 26px;
  }
  .careers-listing .careers-list li .title {
    margin-bottom: 15px;
  }
  .not-found .not-found-content {
    height: 450px;
  }
  .archive .news-list-section {
    padding-top: 150px;
  }
}
