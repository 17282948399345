footer {
    padding: 100px 0;
    background-color: $blue;
    position: relative;
    color: white;
    
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../../inc/assets/circle-patterns.svg');
        background-position: center;
        background-position: center left -100px;
        background-repeat: no-repeat;
        opacity: 0.1;
    }

    a {
        color: white;
    }

    .title {
        margin-bottom: 60px;
    }

    .widget.text-2 {
        margin-bottom: 60px;
    }

    .textwidget {
        margin-bottom: 0;
        h6 {
            margin-bottom: 20px;
        }
    }

    .social-link-title {
        .title {
            margin-bottom: 20px;
        }

        .footer-social {
            li {
                margin-right: 20px;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    .address-col {
        .title {
            margin-bottom: 40px;
        }
    }

    .address-wrapper {
        margin-bottom: 60px;

        .title {
            margin-bottom: 20px;
        }

        .content {
            position: relative;

            p {
                width: 80%;
                &:last-of-type {
                    margin-bottom: 20px;
                }
            }
        }

        img {
            &.address-icon {
                position: absolute;
                top: 3px;
                left: -38px;
                width: 20px;
            }
        }

        .social-links {
            display: flex;
            list-style: none;
            padding-left: 0;
            margin-top: 20px;
            margin-bottom: 20px;

            li {
                &:not(:last-child) {
                    margin-right: 10px;
                }
                
                a {
                    width: 24px;
                    height: 24px;
                    @include flex;
                    @include justify-content(center);
                    @include align-items(center);
                    border-radius: 100%;
                    background-color: $dark-blue;
                    padding: 5px;

                    img {
                        width: 10px;
                        height: auto;
                    }
                }
            }
        }
    }
}