header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: $blue;
	color: #fff;
	font-size: 18px;
	line-height: 24px;
	z-index: 15;

	.navbar {
		padding: 0 60px;

		.navbar-brand {
			img {
				width: 80%;
			}
		}
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.contact-btn {
		@include flex;
		@include align-items(center);
	}

	.mobile-contact-btn {
		display: none;
	}

	.navigation-links {
		.menu-item {
			position: relative;

			.menu-image-hover-wrapper {
				margin: 0;
				padding: 0;
				img {
					width: 40px;
					padding-right: 0;
					margin-right: 18px;
				}

				.hovered-image {
					display: none;
				}

				.menu-image {
					display: inline-block;
				}
			}

			.menu-image-title {
				padding-left: 0;
			}

			&:hover {
				> a {
					background-color: white;
					color: $blue;
				}

				.mobile-menu-icon {
					span {
						border-color: $dark-blue;
					}
				}
			}

			> a {
				color: white;
				padding: 50px 20px;
				font-weight: 700;
				@include transitionAll(0.3s ease-in-out);
			}

			.mobile-menu-icon {
				display: none;
				width: 25px;
				height: 25px;
				position: absolute;
			    top: 15px;
			    right: 15px;
			    @include align-items(center);
			    @include justify-content(center);

				span {
				    border-style: solid;
				    border-width: 2px 2px 0 0;
				    border-color: #fff;
				    height: 7px;
				    width: 7px;
				    padding: 3px;
				    @include rotate(135deg);
				    @include transitionAll(0.3s ease-in-out);
				}
			}

			&.menu-item-has-children {

				&:hover {

					> a {
						&:after {
							@include rotate(-45deg);
						}
					}

					.sub-menu {
						opacity: 1;
						visibility: visible;
					}
				}

				> a {
					position: relative;
					&:after {
						content: '';
					    display: inline-block;
					    border-style: solid;
					    border-width: 2px 2px 0 0;
					    height: 7px;
					    width: 7px;
					    margin-left: 8px;
					    vertical-align: middle;
					    line-height: normal;
					    @include rotate(135deg);
					    -webkit-transition: -webkit-transform 0.3s ease-in-out;
					    transition: -webkit-transform 0.3s ease-in-out;
					    -o-transition: transform 0.3s ease-in-out;
					    transition: transform 0.3s ease-in-out;
					    transition: transform 0.3s ease-in-out,
					    -webkit-transform 0.3s ease-in-out;
					}
				}
			}

			&.current-menu-item, &.current-page-ancestor {
				> a {
					background-color: white;
					color: $blue;
				}
			}

			&.show {
				.sub-menu {
					height: auto;
				}
			}
		}

		.sub-menu {
			position: absolute;
			opacity: 0;
			visibility: hidden;
			top: 100%;
			left: 0;
			width: 342px;
			background-color: white;
			z-index: 2;
			border-radius: 0 0 10px 10px;
			box-shadow: 0 10px 10px rgba(0,0,0,0.1);
			@include transitionAll(0.3s ease-in-out);

			li {
				> a {
					display: block;
					color: $blue;
					padding: 30px 40px;
				    border-radius: 10px;

				    &:hover {
				    	color: white;
				    	background-color: $blue;

				    	span {
				    		color: white;
				    	}
				    }

					span {
						color: $blue;
					}
				}

				&.current-menu-item {
					> a {
						background-color: $blue;
						color: white;

						span {
							color: white;
						}
					}

					.menu-image-hover-wrapper {
						.menu-image {
							display: none;
						}

						.hovered-image {
							display: inline-block;
							opacity: 1;
						}
					}
				}

				&:hover {
					.menu-image-hover-wrapper {

						.hovered-image {
							display: inline-block;
						}

						.menu-image {
							display: none;
						}
					}
				}
			}
		}
	}


}

.admin-bar {
	header {
		top: 32px;
	}
}

.contact-page {
    header {
        background-color: $blue;
    }
}