@include responsive(desktop, max) {
    // 9. Gallery Block
    .gallery-block {
        .swiper-slide {
            img {
                object-fit: cover;
                height: 350px;
            }
        }
    }
}

@include responsive(medium-desktop, max) {
    // 1. Hero Banner Block Subpage
    .hero-banner-block-subpage {
        // padding: 360px 0 290px;
    }


    // 9. Gallery Block
    .gallery-block {
        .swiper-button-next {
            right: 15px;
        }

        .swiper-button-prev {
            left: 15px;
        }
    }


    // 13. Meet the Team Block
    .team-block {
        .team-next-nav {
            right: 15px;
        }

        .team-previous-nav {
            left: 15px;
        }
    }


    // News Block
    .news-block {
        .news-next-nav {
            right: 15px;
        }

        .news-previous-nav {
            left: 15px;
        }
    }
}


@include responsive(mini-desktop, max) {
    // Header
    header {
        font-size: 16px;
        line-height: 24px;
    }

    header .navbar {
        padding: 0 30px;

        .navigation-links {
            .menu-item {
                > a {
                    padding: 50px 15px;
                }
            }
        }
    }

    .navbar-brand img {
        width: 120px;
    }

    // 1. Hero Banner Block Homepage
    .hero-banner-block-homepage {
        .row {
            height: 700px;
        }

        .title-col {
            margin-top: 50px;
        }
    }

    // 3. Content With Image Block
    .content-with-image-block {
        .content-block {
            padding: 100px 6rem;
        }
    }


    // 4. Branded Image with Content
    .branded-image-with-content {
        padding: 160px 0;
    }

}


@include responsive(laptop, max) {
    // Header
    header {
        font-size: 14px;
        line-height: 20px;
    }

    header .navbar {
        padding: 0 20px;

        .navigation-links {
            .menu-item {
                > a {
                    padding: 30px 10px;
                }
            }
        }

        .btn.btn-expand {
            img {
                margin-left: 5px;
            }
        }
    }

    .navbar-brand img {
        width: 95px;
    }


    // 3. Content With Image Block
    .content-with-image-block {
        .content-block {
            padding: 100px 6rem;
        }
    }


    // 4. Branded Image with Content
    .branded-image-with-content {
        padding: 120px 0;
    }


    // 5. Our Brands Block
    .our-brands-block {
        .our-brands-wrapper {
            .size-icon {
                margin-bottom: 30px;
            }
        }
    }

    // 6. Core Values Block
    .core-values-block {
        padding: 100px 0 70px;
    }


    // News Block
    .news-block, .news-list-section {
        .news-wrapper {
            padding: 20px;

            .news-image {
                margin-bottom: 20px;
            }

            .date {
                margin-bottom: 10px;
            }
        }
    }

    .post-content {
        padding-top: 160px;

        .title-main {
            width: 65%;
        }
    }

    // Home Page
    .home {
        .news-block {

            .news-wrapper {
                margin-top: 20px;
            }
        }
    }

    // Contact Page
    .contact-form {
        padding: 150px 0 0;
    }

    // 404 Page
    .not-found {
        padding-top: 80px;
    }

    // Careers Listing
    .careers-listing {
        padding: 100px 0;
    }

    // Single Post
    .career-single-post {
        padding-top: 170px;

        .title-main {
            margin-top: 0;
        }

        .single-post-right {
            padding-left: 5px;
        }
    }
}


@include responsive(ipad-portrait, max) {
    // Header
    header {
        .navbar {
            padding: 15px;

            .navigation-links {
                .menu-item {
                    > a {
                        display: block;
                        font-size: 16px;
                        padding: 20px 15px;
                    }

                    &.menu-item-has-children {
                        > a {
                            &:after {
                                display: none;
                            }
                        }
                    }

                    .mobile-menu-icon {
                        @include flex;
                    }

                    &.show {
                        .mobile-menu-icon {
                            span {
                                @include rotate(-45deg);
                            }
                        }

                        .sub-menu {
                            opacity: 1;
                            visibility: visible;
                            height: auto;
                        }
                    }

                    &:hover {
                        > a {
                            background: transparent;
                            color: white;
                        }

                        .mobile-menu-icon {
                            span {
                                border-color: white;
                            }
                        }
                    }

                    &.current_page_item {
                        &:hover {
                            > a {
                                background: #fff;
                                color: $dark-blue;
                            }
                        }

                        .mobile-menu-icon {
                            span {
                                border-color: $dark-blue;
                            }
                        }
                    }
                }

                .sub-menu {
                    position: relative;
                    width: 100%;
                    height: 0;
                    overflow: hidden;
                }
            }
        }

        &.dark {
            .navbar-collapse {
                background-color: rgba(0,0,0,0.9);

                .navigation-links {
                    background-color: rgba(0,0,0,0.9);

                    .menu-item {

                        &:hover {
                            > a {
                                background-color: $dark-blue;

                                &:before {
                                    display: none;
                                }
                            }
                        }

                        &.current_page_item {
                            .mobile-menu-icon {
                                span {
                                    border-color: white;
                                }
                            }
                        }

                        &.current-menu-item {
                            > a {
                                background-color: $dark-blue;
                                color: white;
                                &:before {
                                    content: '';
                                    position: absolute;
                                    bottom: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 8px;
                                    border-radius: 4px;
                                    background: transparent;
                                }
                            }
                        }
                    }

                    .sub-menu {
                        li {
                            > a {
                                &:before {
                                    left: 0;
                                    width: 100%;
                                    @include translateX(0);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .navbar-toggler {
        width: 40px;
        height: 40px;
        padding: 0;
        margin-right: 15px;
        position: relative;
        @include flex;
        @include align-items(center);

        &:focus {
            outline: none;
            box-shadow: none;
            border: none;
        }

        .navbar-toggler-line {
            width: 100%;
            height: 3px;
            background-color: white;
            border-radius: 5px;
            position: relative;
            @include transitionAll(0.3s ease-in-out);

            &:before, &:after {
                content: '';
                width: 100%;
                height: 100%;
                background-color: white;
                position: absolute;
                left: 0;
                @include transitionAll(0.3s ease-in-out);
            }
            &:before {
                top: -10px;
            }
            &:after {
                top: 10px;
            }
        }

        &:not(.collapsed) {
            .navbar-toggler-line {
                background-color: transparent;
                &:before {
                    top: 0;
                    @include rotate(45deg);
                }

                &:after {
                    top: 0;
                    @include rotate(-45deg);
                }
            }
        }
    }

    .navbar-collapse {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: $dark-blue;
        box-shadow: 0 10px 20px rgba(0,0,0,0.1);

        .navigation-links {
            display: block;
            background-color: $dark-blue;
            max-height: 500px;
            overflow-y: auto;
        }
    }


    // 2. Hero Banner Block Subpage
    .hero-banner-block-subpage {
        // padding: 200px 0;

        &:after {
            bottom: -40px;
            left: 15%;
            height: 200px;
        }
    }


    // 3. Content With Image Block
    .content-with-image-block {
        .image-block {
            height: 500px;
            @include borderRadius(0);
        }

        .content-block {
            padding: 50px 4rem;
        }
    }


    // 4. Branded Image with Content
    .branded-image-with-content {
        padding: 70px 0;
    }


    // 5. Our Brands Block
    .our-brands-block {
        padding: 70px 0;

        .title {
            margin-bottom: 30px;
        }

        p {
            &:last-of-type {
                margin-bottom: 50px;	
            }
        }

        .our-brands-wrapper {
            margin-bottom: 20px 20px 50px;
        }
    }

    // 6. Core Values Block
    .core-values-block {
        padding: 70px 0;

        .title {
            margin-bottom: 30px;
        }

        p {
            margin-bottom: 50px;
        }
        .core-values-wrapper {

            .core-values-img {
                margin-bottom: 40px;
            }
        }
    }

    // 7. Content, Logo and Image Block
    .content-logo-image-block {
        [class$="row-reverse "],[class$="row-reverse"] {
            .image-block {
                @include borderRadius(0);
            }
        }
    }

    // 8. Content Block
    .content-block {
        padding: 70px 0;

    }

    // 9. Gallery Block
    .gallery-block {
        padding: 70px 0;

    }

    // 10. Testimonial Block
    .testimonials-block {
        &:before {
            right: 80px;
        }

        &:after {
            left: 80px;
        }
    }


    // 11: Image and Form Block
    .image-form-block {
        .image-block {
            height: 450px;
            border-radius: 0;
        }

        .form-block {
            padding-top: 70px;
            padding-bottom: 70px;
        }
    }


    // 13. Meet the Team Block
    .team-block {
        padding: 70px 0;

        .title, .sub-title {
            margin-bottom: 20px;
        }
    }

    // 14. Image Testimonials
    .image-testimonials-block {
        padding-top: 0;

        .image-block {
            height: 500px;
            @include borderRadius(0);
        }
    }


    // News Block
    .news-block {
        padding: 70px 0;
    }

    .news-wrapper {
        margin-bottom: 20px;
        padding: 20px;

        &.news-recent-wrapper {

            .content-wrapper {
                padding: 0 0 0 30px;
            }
        }
    }

    .news-list-section {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .post-content {

        .title-main {
            width: 80%;
        }
    }


    // Footer
    footer {
        padding: 70px 0;

        .title {
            margin-bottom: 25px;
        }

        .textwidget h6 {
            margin-bottom: 10px;
        }

        .widget.text-2 {
            margin-bottom: 30px;
        }

        .address-col {
            .title {
                margin-bottom: 20px;
            }
        }

        .address-wrapper {
            .content {
                padding-left: 40px;

                img {
                    &.address-icon {
                        left: 0;
                        top: 0;
                    }
                }

                a {
                    display: block;
                }
            }
        }
    }

    // News Page
    .news-page {
        .news-wrapper {
            margin-bottom: 20px;
        }
    }

    // Contact Page
    .contact-form {

        iframe {
            margin-top: 70px;
            height: 450px;
        }
    }

    // Pagination 
    .paging-navigation {
        .nav-links {
            margin-top: 30px;
        }
    }

    // Investment Opportunities Page
    .investment-opportunities-page {

        .testimonials-block {
            margin: 0;
        }
    }

    // Careers Listing
    .careers-listing {
        padding: 70px 0;
    }

    // Single Post
    .career-single-post {

        .single-post-left {
            .wp-block-table {
                padding: 19px 20px 16px;
            }
        }
        .single-post-right {
            margin-top: 0;
        }
    }

}

@include responsive(mobile-big, max) {
    // 2. Hero Banner Block Subpage
    .hero-banner-block-subpage {
        // padding: 150px 0 200px;
    }

    .hero-banner-block-homepage {
        .row {
            min-height: 425px;
            height: 50vh;

            .title-col {
                margin-bottom: 0;
                
                p {
                    margin-bottom: 0;
                }
            }
        }
    }


    // News Block
    .news-wrapper {
        &.news-recent-wrapper {

            .image-wrapper {
                width: 100%;
                margin: 0 0 30px;
            }

            .content-wrapper {
                width: 100%;
                padding: 0;
            }
        }
    }

    .post-content {
        padding-top: 140px;

        .title-main {
            width: 100%;
        }

        .full-img {
            margin-bottom: 40px;
        }

        .title {
            margin-bottom: 30px;
        }

        .wp-block-columns {
            margin-bottom: 0;

            .wp-block-column {
                margin-bottom: 20px;
            }
        }
    }

    // 8. Content Block
    .content-block {
        padding: 50px 0;
        .logo-icon, .title {
            margin-bottom: 30px;
        }
    }

    // 9. Gallery Block
    .gallery-block {
        padding: 30px 0;
    }

    // Similar Posts
    .similar-posts {
        padding: 50px 0;
    }

    // Footer
    footer {
        padding: 50px 0;

        .address-wrapper {
            margin-bottom: 40px;
        }
    }

    // Contact Page
    .contact-form {

        .contact-content {
            margin-bottom: 50px;
            padding-right: 0;

            img {
                margin-bottom: 20px;
            }


        }
    }

    // Careers Listing
    .careers-listing {

        .careers-list {
            li {
                padding: 19px 20px 16px;
            }
        }
    }

    // Single Post
    .career-single-post {
        padding-top: 140px;
    }

    .gallery-block {
        .gallery-slider {
            .inner-slide-content {
                height: 100vh;
                min-height: 250px;
                max-height: 250px;
            }
        }
    }
}


@include responsive(mobile-small, max) {
    // Header
    header {
        .mobile-contact-btn {
            display: inline-block;
            padding: 35px 15px;
            margin: 20px 0;
        }

        .contact-btn {
            display: none;
        }
    }

    .navbar-toggler {
        margin-right: 0;
    }


    // 1. Hero Banner Block Homepage
    .hero-banner-block-homepage {
        .title-col {
            text-align: center;

            h1 {
                font-size: 30px;
                line-height: 40px;
            }
        }
    }


    // 3. Content With Image Block
    .content-with-image-block {
        .image-block {
            height: 400px;

            &:before, &:after {
                height: 200px;
            }

            &:before {
                right: 50px;
            }

            &:after {
                left: 50px;
                bottom: -100px;
            }
        }

        .content-block {
            padding-top: 50px;
            padding-bottom: 50px;

            .title {
                margin-bottom: 20px;
            }

            p {
                &:last-of-type {
                    margin-bottom: 25px;
                }
            }
        }
    }


    // 4. Branded Image with Content
    .branded-image-with-content {
        padding: 50px 0;

        .title {
            margin-bottom: 20px;
        }

        .btn {
            margin-left: 0;
        }
    }


    // 5. Our Brands Block
    .our-brands-block {
        padding: 30px 0;

        .our-brands-wrapper {
            padding: 25px 25px 55px;
        }

        .title {
            margin-bottom: 20px;
        }
    }

    // 6. Core Values Block
    .core-values-block {
        padding: 50px 0 30px;

        .title {
            margin-bottom: 20px;
        }

        .core-values-wrapper {
            padding: 25px;

            .core-values-img {
                margin-bottom: 30px;
            }
        }
    }

    // 7. Content, Logo and Image Block
    .content-logo-image-block {
        .content-block {
            .full-title {
                flex-direction: column;
                padding: 15px;

                .btn {
                    margin: 10px auto 0;
                    font-size: 13px;
                }
            }
        }
    }

    // 9. Gallery Block
    .gallery-block {
        .swiper-button-next {
            top: calc(50% - 30px);
            right: 12px;

            &:after {
                right: -10px;
                width: 30px;
                height: 30px;
            }
        }

        .swiper-button-prev {
            top: calc(50% - 30px);
            left: 12px;

            &:after {
                left: -10px;
                width: 30px;
                height: 30px;
            }
        }

        .swiper-slide {
            img {
                height: 260px;
            }
        }
    }


    // 10. Testimonial Block
    .testimonials-block {
        padding: 70px 0;

        &:before, &:after {
            height: 90px;
        }
    }


    // 11: Image and Form Block
    .image-form-block {
        .image-block {
            height: 300px;
        }

        .form-block {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }


    // 13. Meet the Team Block
    .team-block {
        .team-previous-nav {
            left: 35px;

            &:after {
                left: -10px;
                width: 30px;
                height: 30px;
            }
        }

        .team-next-nav {
            right: 35px;

            &:after {
                right: -10px;
                width: 30px;
                height: 30px;
            }
        }
    }

    // 14. Image Testimonials
    .image-testimonials-block {
        .image-block {

            &:after {
                bottom: -100px;
                left: 50px;
            }

            &:before {
                top: -100px;
                right: 50px;
            }
        }
    }


    // News Block
    .news-block {
        padding: 30px 0;

        .title {
            margin-bottom: 10px;
        }

        .view-all-btn {
            position: relative;
            bottom: 0;
            right: 0;
        }

        .news-pagination {
            width: 100%;
            left: 50%;
            @include translateX(-50%);
        }

        .news-previous-nav {
            left: 35px;

            &:after {
                left: -10px;
                width: 30px;
                height: 30px;
            }
        }

        .news-next-nav {
            right: 35px;

            &:after {
                right: -10px;
                width: 30px;
                height: 30px;
            }
        }
    }

    // Contact Page
    .contact-form {
        padding-bottom: 0;

        iframe {
            height: 350px;
        }
    }

    // Careers Listing
    .careers-listing {

        &:after {
            bottom: -100px;
            left: 50px;
        }

        &:before {
            top: -100px;
            right: 50px;
        }

        .careers-list {
            li {
                padding: 29px 20px 26px;

                .title {
                    margin-bottom: 15px;
                }
            }
        }
    }

    // 404 Page
    .not-found {

        .not-found-content {
            height: 450px;
        }
    }

    .archive {
        .news-list-section {
            padding-top: 150px;
        }    
    }
}