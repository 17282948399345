@use "sass:map";

/*** mixins.scss ***/
// translate
@mixin translate($value, $value1) {
    -webkit-transform: translate($value, $value1);
    -ms-transform:     translate($value, $value1);
    -o-transform:      translate($value, $value1);
    transform:         translate($value, $value1);
}

// translateY
@mixin translateY($value) {
    -webkit-transform: translateY($value);
    -moz-transform: translateY($value);
    -ms-transform: translateY($value);
    transform: translateY($value);
}

// translateX
@mixin translateX($value) {
    -webkit-transform: translateX($value);
    -moz-transform: translateX($value);
    -ms-transform: translateX($value);
    transform: translateX($value);
}

// scale
@mixin scale($value) {
    -moz-transform: scale($value);
    -webkit-transform: scale($value);
    -ms-transform: scale($value);
    transform: scale($value);
}

@mixin scaleY($value) {
    -moz-transform: scaleY($value);
    -webkit-transform: scaleY($value);
    -ms-transform: scaleY($value);
    transform: scaleY($value);
}

@mixin scaleX($value) {
    -moz-transform: scaleX($value);
    -webkit-transform: scaleX($value);
    -ms-transform: scaleX($value);
    transform: scaleX($value);
}

// rotate
@mixin rotate($value) {
    -moz-transform: rotate($value);
    -webkit-transform: rotate($value);
    -ms-transform: rotate($value);
    transform: rotate($value);
}

// skew
@mixin skew($value) {
    -moz-transform: skew($value);
    -webkit-transform: skew($value);
    -ms-transform: skew($value);
    transform: skew($value);
}

// skewX
@mixin skewX($value) {
    -moz-transform: skewX($value);
    -webkit-transform: skewX($value);
    -ms-transform: skewX($value);
    transform: skewX($value);
}

@mixin skewY($value) {
    -moz-transform: skewY($value);
    -webkit-transform: skewY($value);
    -ms-transform: skewY($value);
    transform: skewY($value);
}

// border radius
@mixin borderRadius($value) {
    -webkit-border-radius: $value;
    -moz-border-radius: $value;
    -ms-border-radius: $value;
    border-radius: $value;
}

// transitions
@mixin transitionAll($value) {
    -webkit-transition: $value;
    -moz-transition: $value;
    -ms-transition: $value;
    transition: $value;
}

// box shadow
@mixin box-shadow($value) {
    -webkit-box-shadow: $value;
    -moz-box-shadow:    $value;
    -ms-box-shadow:     $value;
    -o-box-shadow:      $value;
    box-shadow:         $value;
}

// Flex
@mixin flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
       -moz-box-pack: start;
       -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
       -moz-box-pack: end;
       -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
       -moz-box-pack: justify;
       -ms-flex-pack: justify;
  } @else if $value == space-around {
       -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
       -moz-box-pack: $value;
       -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
          justify-content: $value;
}

@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
       -moz-box-align: start;
       -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
       -moz-box-align: end;
       -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
       -moz-box-align: $value;
       -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
          align-items: $value;
}

// Breakpoint Mixin

@mixin responsive($breakpoint, $dir) {
    @media only (#{$dir}-width: map-get($breakpoints, "#{$breakpoint}")) {
        @content;
    }
}